// src/pages/Financials.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import FinancialsTable from '../components/FinancialsTable';

const Financials = () => {
  return (
    <div className="flex">
      <Sidebar />
      <div className="ml-64 flex-1">
        <TopBar />
        <FinancialsTable />
      </div>
    </div>
  );
};

export default Financials;
