import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api'; // Adjust the path according to your project structure

const RegistrationFees = () => {
  const [fees, setFees] = useState([]);
  const [form1to4Fee, setForm1to4Fee] = useState('');
  const [form5to6Fee, setForm5to6Fee] = useState('');
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchRegistrationFees();
  }, []);

  // Fetch the current registration fees from the backend
  const fetchRegistrationFees = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/registrations`);
      const data = response.data.data;

      // Find the fees for the two ranges
      const form1to4 = data.find((fee) => fee.form_range === 'Form 1-4');
      const form5to6 = data.find((fee) => fee.form_range === 'Form 5-6');

      setFees(data);
      setForm1to4Fee(form1to4 ? form1to4.amount : '');
      setForm5to6Fee(form5to6 ? form5to6.amount : '');
      setLoading(false);
    } catch (error) {
      console.error('Error fetching registration fees:', error);
      setErrorMessage('Failed to fetch registration fees.');
      setLoading(false);
    }
  };

  // Handle the update for Form 1-4
  const handleUpdateForm1to4Fee = async () => {
    if (!form1to4Fee || isNaN(form1to4Fee)) {
      setErrorMessage('Please enter a valid fee for Form 1-4');
      return;
    }
    try {
      await axios.put(`${BASE_URL}/registrations`, {
        formRange: 'Form 1-4',
        newAmount: form1to4Fee
      });
      setSuccessMessage('Form 1-4 fee updated successfully.');
      setErrorMessage('');
      fetchRegistrationFees();
    } catch (error) {
      console.error('Error updating Form 1-4 fee:', error);
      setErrorMessage('Failed to update Form 1-4 fee.');
    }
  };

  // Handle the update for Form 5-6
  const handleUpdateForm5to6Fee = async () => {
    if (!form5to6Fee || isNaN(form5to6Fee)) {
      setErrorMessage('Please enter a valid fee for Form 5-6');
      return;
    }
    try {
      await axios.put(`${BASE_URL}/registrations`, {
        formRange: 'Form 5-6',
        newAmount: form5to6Fee
      });
      setSuccessMessage('Form 5-6 fee updated successfully.');
      setErrorMessage('');
      fetchRegistrationFees();
    } catch (error) {
      console.error('Error updating Form 5-6 fee:', error);
      setErrorMessage('Failed to update Form 5-6 fee.');
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">Registration Fees</h2>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <table className="min-w-full bg-white">
            <thead>
              <tr className="w-full bg-gray-200 text-left">
                <th className="py-2 px-4">Form Range</th>
                <th className="py-2 px-4">Current Fee (USD)</th>
                <th className="py-2 px-4">New Fee (USD)</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {/* Form 1-4 */}
              <tr>
                <td className="py-2 px-4">Form 1-4</td>
                <td className="py-2 px-4">{fees.find(fee => fee.form_range === 'Form 1-4')?.amount}</td>
                <td className="py-2 px-4">
                  <input
                    type="text"
                    value={form1to4Fee}
                    onChange={(e) => setForm1to4Fee(e.target.value)}
                    className="border p-1 rounded w-full"
                  />
                </td>
                <td className="py-2 px-4">
                  <button
                    className="bg-blue-500 text-white py-1 px-4 rounded"
                    onClick={handleUpdateForm1to4Fee}
                  >
                    Update
                  </button>
                </td>
              </tr>

              {/* Form 5-6 */}
              <tr>
                <td className="py-2 px-4">Form 5-6</td>
                <td className="py-2 px-4">{fees.find(fee => fee.form_range === 'Form 5-6')?.amount}</td>
                <td className="py-2 px-4">
                  <input
                    type="text"
                    value={form5to6Fee}
                    onChange={(e) => setForm5to6Fee(e.target.value)}
                    className="border p-1 rounded w-full"
                  />
                </td>
                <td className="py-2 px-4">
                  <button
                    className="bg-blue-500 text-white py-1 px-4 rounded"
                    onClick={handleUpdateForm5to6Fee}
                  >
                    Update
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          {/* Success or Error Message */}
          {successMessage && (
            <p className="bg-green-100 text-green-700 p-2 mt-4 rounded">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="bg-red-100 text-red-700 p-2 mt-4 rounded">{errorMessage}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default RegistrationFees;
