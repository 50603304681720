import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthContext from '../context/AuthContext';

const ViewInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchParams, setSearchParams] = useState({
    classType: '',
    form: '',
    term: '',
    year: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const invoicesPerPage = 10;

  const navigate = useNavigate();
  const { username } = useContext(AuthContext); // Fetch the username from AuthContext

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/invoices`);
        setInvoices(response.data);
        setFilteredInvoices(response.data);
      } catch (error) {
        console.error('Failed to fetch invoices', error);
      }
    };

    fetchInvoices();
  }, []);

  const handleSearch = () => {
    const filtered = invoices.filter(invoice => 
      (searchParams.classType ? invoice.class_type.includes(searchParams.classType) : true) &&
      (searchParams.form ? invoice.form.toString() === searchParams.form : true) &&
      (searchParams.term ? invoice.term.toString() === searchParams.term : true) &&
      (searchParams.year ? invoice.year.toString() === searchParams.year : true)
    );
    setFilteredInvoices(filtered);
    setCurrentPage(1);
  };

  const handleReset = () => {
    setSearchParams({
      classType: '',
      form: '',
      term: '',
      year: '',
    });
    setFilteredInvoices(invoices);
    setCurrentPage(1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  };

  const handleEdit = (invoiceId) => {
    if (username === 'financeadmin' || username === 'sysadmin') {
      navigate(`/edit-invoice/${invoiceId}`);
    } else {
      toast.error("You don't have permission to edit invoices.");
    }
  };

  const handleView = (invoice) => {
    setSelectedInvoice(invoice);
  };

  const closeModal = () => {
    setSelectedInvoice(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${BASE_URL}/invoices/${selectedInvoice.id}`);
      toast.success("Invoice deleted successfully.");
      setInvoices(invoices.filter(invoice => invoice.id !== selectedInvoice.id));
      setFilteredInvoices(filteredInvoices.filter(invoice => invoice.id !== selectedInvoice.id));
      setShowDeleteModal(false);
      setSelectedInvoice(null);
    } catch (error) {
      console.error('Failed to delete invoice', error);
      toast.error('Failed to delete invoice.');
    }
  };

  const openDeleteModal = (invoice) => {
    setSelectedInvoice(invoice);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedInvoice(null);
    setShowDeleteModal(false);
  };

  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

  const renderPageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredInvoices.length / invoicesPerPage); i++) {
    renderPageNumbers.push(
      <button 
        key={i} 
        onClick={() => setCurrentPage(i)} 
        className={`px-3 py-1 mx-1 ${i === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'} rounded`}
      >
        {i}
      </button>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-4 bg-white rounded shadow-md">
      <h2 className="text-xl font-bold mb-4">Invoices</h2>

      {/* Search Filters */}
      <div className="flex space-x-4 mb-4">
        <input
          type="text"
          name="classType"
          placeholder="Class Type"
          value={searchParams.classType}
          onChange={handleChange}
          className="p-2 border rounded w-1/4"
        />
        <input
          type="text"
          name="form"
          placeholder="Form"
          value={searchParams.form}
          onChange={handleChange}
          className="p-2 border rounded w-1/4"
        />
        <input
          type="text"
          name="term"
          placeholder="Term"
          value={searchParams.term}
          onChange={handleChange}
          className="p-2 border rounded w-1/4"
        />
        <input
          type="text"
          name="year"
          placeholder="Year"
          value={searchParams.year}
          onChange={handleChange}
          className="p-2 border rounded w-1/4"
        />
        <button onClick={handleSearch} className="bg-blue-500 text-white px-4 py-2 rounded">Search</button>
        <button onClick={handleReset} className="bg-gray-500 text-white px-4 py-2 rounded">Reset</button>
      </div>

      {/* Invoice Table */}
      <table className="table-auto w-full">
        <thead>
          <tr className="bg-[#BB005A] text-white">
            <th className="px-4 text-start py-2">Class Type</th>
            <th className="px-4 text-start py-2">Form</th>
            <th className="px-4 text-start py-2">Term</th>
            <th className="px-4 text-start py-2">Year</th>
            <th className="px-4 text-start py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentInvoices.map((invoice) => (
            <tr key={invoice.id} className="border-t">
              <td className="px-4 text-start py-2">{invoice.class_type}</td>
              <td className="px-4 text-start py-2">{invoice.form}</td>
              <td className="px-4 text-start py-2">{invoice.term}</td>
              <td className="px-4 text-start py-2">{invoice.year}</td>
              <td className="px-4 text-start py-2">
                <button 
                  onClick={() => handleEdit(invoice.id)} 
                  className="bg-yellow-500 text-white px-3 py-1 rounded mr-2"
                  disabled={!(username === 'financeadmin' || username === 'sysadmin')}
                >
                  Edit
                </button>
                <button 
                  onClick={() => handleView(invoice)} 
                  className="bg-blue-500 text-white px-3 py-1 rounded mr-2"
                >
                  View
                </button>
                {username === 'financeadmin' || username === 'sysadmin' ? (
                  <button 
                    onClick={() => openDeleteModal(invoice)} 
                    className="bg-red-500 text-white px-3 py-1 rounded"
                  >
                    Delete
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        {renderPageNumbers}
      </div>

      {/* Modal for Viewing Invoice Details */}
      {selectedInvoice && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
          <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
            {/* Header with Invoice Details */}
            <div className="mb-4">
              <h3 className="text-lg font-bold mb-2">Invoice #{selectedInvoice.id}</h3>
              <div className="text-sm text-gray-700">
                <p>Class Type: {selectedInvoice.class_type}</p>
                <p>Form: {selectedInvoice.form}</p>
                <p>Term: {selectedInvoice.term}</p>
                <p>Year: {selectedInvoice.year}</p>
                <p>RTGS Percentage: {selectedInvoice.rtgs_percentage}%</p>
              </div>
            </div>
            
            {/* Items Section */}
            <h4 className="text-md font-semibold mt-2 mb-2">Items:</h4>
            <ul className="list-none">
              {selectedInvoice.items.map((item) => (
                <li key={item.id} className="flex justify-between">
                  <span>{item.item_name}</span>
                  <span>${item.amount}</span>
                </li>
              ))}
            </ul>

            {/* Total Amount */}
            <div className="mt-4 font-bold text-lg flex justify-between">
              <span>Total Amount:</span>
              <span>${selectedInvoice.total_amount}</span>
            </div>

            {/* Close Button */}
            <button onClick={closeModal} className="mt-6 bg-blue-500 text-white px-4 py-2 rounded">Close</button>
          </div>
        </div>
      )}

      {/* Modal for Delete Confirmation */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
            <h3 className="text-xl font-bold mb-4">Confirm Deletion</h3>
            <p className="mb-4">You are about to delete this invoice. If there are students who have already paid, their balances will be affected.</p>
            <div className="flex justify-end">
              <button 
                onClick={closeDeleteModal} 
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button 
                onClick={handleDelete} 
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewInvoices;
