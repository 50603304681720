import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';

const AddTimetablePage = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [timetableEntries, setTimetableEntries] = useState({});
  const [newEntry, setNewEntry] = useState({ item: '', startTime: '', endTime: '', day: 'Monday' });
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/getgradelevelclass`);
      setClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const handleAddEntry = () => {
    if (!newEntry.item || !newEntry.startTime || !newEntry.endTime) {
      alert('All fields must be filled');
      return;
    }

    const updatedEntries = {
      ...timetableEntries,
      [newEntry.day]: [
        ...(timetableEntries[newEntry.day] || []),
        {
          item: newEntry.item,
          startTime: newEntry.startTime,
          endTime: newEntry.endTime,
        },
      ],
    };

    setTimetableEntries(updatedEntries);
    setNewEntry({ item: '', startTime: '', endTime: '', day: newEntry.day }); // Reset the form fields
  };

  const handleSaveTimetable = async () => {
    if (!selectedClass) return alert('Please select a class');

    try {
      const timetableData = Object.entries(timetableEntries).flatMap(([day, entries]) =>
        entries.map(entry => ({
          item: entry.item,
          startTime: entry.startTime,
          endTime: entry.endTime,
          day: day,
        }))
      );

      await axios.post(`${BASE_URL}/timetable`, {
        classID: selectedClass,
        timetableEntries: timetableData,
      });

      alert('Timetable saved successfully');
    } catch (error) {
      console.error('Error saving timetable:', error);
      setShowErrorModal(true);
    }
  };

  const closeModal = () => {
    setShowErrorModal(false);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Add Timetable</h2>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Select Class:</label>
        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Select a class</option>
          {classes.map((cls) => (
            <option key={cls.ClassID} value={cls.ClassID}>
              {cls.ClassName}
            </option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700 mb-2">Day:</label>
          <select
            value={newEntry.day}
            onChange={(e) => setNewEntry({ ...newEntry, day: e.target.value })}
            className="w-full p-2 border rounded"
          >
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-gray-700 mb-2">Item:</label>
          <input
            type="text"
            value={newEntry.item}
            onChange={(e) => setNewEntry({ ...newEntry, item: e.target.value })}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700 mb-2">Start Time:</label>
          <input
            type="time"
            value={newEntry.startTime}
            onChange={(e) => setNewEntry({ ...newEntry, startTime: e.target.value })}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700 mb-2">End Time:</label>
          <input
            type="time"
            value={newEntry.endTime}
            onChange={(e) => setNewEntry({ ...newEntry, endTime: e.target.value })}
            className="w-full p-2 border rounded"
          />
        </div>
      </div>
      <button
        onClick={handleAddEntry}
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
      >
        Add Entry
      </button>
      {Object.keys(timetableEntries).length > 0 && (
        <div>
          <h3 className="text-xl font-semibold mb-2 mt-4">Current Entries</h3>
          {Object.entries(timetableEntries).map(([day, entries]) => (
            <div key={day}>
              <h4 className="font-semibold mt-4">{day}</h4>
              <table className="min-w-full bg-white border border-gray-200 rounded-lg mt-2">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="py-2 px-4 text-start border-b">Item</th>
                    <th className="py-2 px-4 text-start border-b">Start Time</th>
                    <th className="py-2 px-4 text-start border-b">End Time</th>
                  </tr>
                </thead>
                <tbody>
                  {entries.map((entry, idx) => (
                    <tr key={`${day}-${idx}`}>
                      <td className="py-2 px-4 border-b">{entry.item}</td>
                      <td className="py-2 px-4 border-b">{entry.startTime}</td>
                      <td className="py-2 px-4 border-b">{entry.endTime}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}
      <button
        onClick={handleSaveTimetable}
        className="mt-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-300"
      >
        Save Timetable
      </button>

      {/* Error Modal */}
      {showErrorModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-md max-w-md mx-auto">
            <h3 className="text-xl font-semibold mb-4">Error</h3>
            <p>There was an error. Either the timetable already exists or a network issue occurred.</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={closeModal}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddTimetablePage;
