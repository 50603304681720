import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import { useNavigate } from 'react-router-dom';

const AddGradeLevelClass = () => {
  const [formData, setFormData] = useState({
    ClassName: '',
    Form: '',
    ClassType: '',
    Teacher: '',
    TeacherName: '',
    Term: '',
    Year: '',
  });
  const [teachers, setTeachers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({ isOpen: false, title: '', message: '' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await fetchTeachers();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchTeachers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/createemployee/teachers/teachers`);
      setTeachers(response.data);
    } catch (error) {
      setModal({
        isOpen: true,
        title: 'Error',
        message: 'Failed to fetch teachers.',
      });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTeacherChange = (e) => {
    const selectedTeacher = teachers.find((teacher) => teacher.EmployeeNumber === e.target.value);
    setFormData({
      ...formData,
      Teacher: selectedTeacher ? selectedTeacher.EmployeeNumber : '',
      TeacherName: selectedTeacher ? `${selectedTeacher.name} ${selectedTeacher.surname}` : '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(`${BASE_URL}/creategradelevelclass`, formData);
      setModal({
        isOpen: true,
        title: 'Success',
        message: 'Class created successfully!',
      });
    } catch (error) {
      setModal({
        isOpen: true,
        title: 'Error',
        message: 'Error creating class. Either the class exists or there’s a network issue.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setModal({ isOpen: false, title: '', message: '' });
    if (modal.title === 'Success') {
      navigate('/classes');
    }
  };

  return (
    <div className="p-4 relative">
      <h2 className="text-2xl font-semibold mb-4">Create New Form Level Class</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block">Class Name</label>
          <input
            type="text"
            name="ClassName"
            value={formData.ClassName}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          />
        </div>
        <div>
          <label className="block">Form</label>
          <select
            name="Form"
            value={formData.Form}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          >
            <option value="">Select Form</option>
            {[1, 2, 3, 4, 5, 6].map((form) => (
              <option key={form} value={form}>
                {form}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Class Type</label>
          <select
            name="ClassType"
            value={formData.ClassType}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          >
            <option value="">Select Class Type</option>
            {formData.Form >= 1 && formData.Form <= 4 ? (
              <>
                <option value="Maroon">Maroon</option>
                <option value="Gray">Gray</option>
                <option value="White">White</option>
                <option value="Red">Red</option>
                <option value="Orange">Orange</option>
                <option value="Green">Green</option>
                <option value="Blue">Blue</option>
              </>
            ) : formData.Form >= 5 && formData.Form <= 6 ? (
              <>
                <option value="Gold">Gold</option>
                <option value="Purple">Purple</option>
                <option value="Silver">Silver</option>
                <option value="Sciences">Sciences</option>
                <option value="Commercials">Commercials</option>
                <option value="Arts">Arts</option>
              </>
            ) : null}
          </select>
        </div>
        <div>
          <label className="block">Teacher</label>
          <select
            name="Teacher"
            value={formData.Teacher}
            onChange={handleTeacherChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          >
            <option value="">Select Teacher</option>
            {Array.isArray(teachers) && teachers.map((teacher) => (
              <option key={teacher.EmployeeNumber} value={teacher.EmployeeNumber}>
                {`${teacher.name} ${teacher.surname}`}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Term</label>
          <select
            name="Term"
            value={formData.Term}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          >
            <option value="">Select Term</option>
            {[1, 2, 3].map((term) => (
              <option key={term} value={term}>
                {term}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Year</label>
          <select
            name="Year"
            value={formData.Year}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          >
            <option value="">Select Year</option>
            {Array.from({ length: 78 }, (_, i) => 2023 + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          disabled={isLoading}
        >
          {isLoading ? 'Creating...' : 'Create Class'}
        </button>
      </form>

      {/* Modal */}
      {modal.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-md max-w-md mx-auto">
            <h3 className="text-xl font-semibold mb-4">{modal.title}</h3>
            <p>{modal.message}</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={closeModal}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Loader */}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25 z-40">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-16 w-16 border-t-blue-500 animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default AddGradeLevelClass;
