import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';

const ViewSubjectClass = () => {
  const { classId } = useParams();
  const navigate = useNavigate();
  const [subjectClass, setSubjectClass] = useState(null);
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showClassDeleteModal, setShowClassDeleteModal] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const [regNumber, setRegNumber] = useState('');
  const [activeSection, setActiveSection] = useState('classInfo');
  const [searchQuery, setSearchQuery] = useState('');
  const [genderFilter, setGenderFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const studentsPerPage = 10;

  useEffect(() => {
    const fetchClassData = async () => {
      try {
        const classResponse = await axios.get(`${BASE_URL}/subjectlevelclass/${classId}`);
        setSubjectClass(classResponse.data);
      } catch (error) {
        console.error('Error fetching class data:', error);
      }
    };

    fetchClassData();
  }, [classId]);

  const fetchStudents = async () => {
    try {
      const studentsResponse = await axios.get(`${BASE_URL}/subjectlevel/${classId}`);
      setStudents(studentsResponse.data.students);
      setFilteredStudents(studentsResponse.data.students);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const handleRemoveStudent = async () => {
    try {
      await axios.delete(`${BASE_URL}/subjectlevel`, { data: { RegNumber: studentToDelete.RegNumber, classId } });
      fetchStudents();
      setShowDeleteModal(false);
      toast.success('Student removed successfully!');
    } catch (error) {
      console.error('Error removing student:', error);
      toast.error('Error removing student');
    }
  };

  const handleAddStudent = async () => {
    try {
      await axios.post(`${BASE_URL}/subjectlevel/`, { regNumber, classId });
      fetchStudents();
      setShowAddModal(false);
      setRegNumber('');
      toast.success('Student added successfully!');
    } catch (error) {
      console.error('Error adding student:', error);
      toast.error('Error adding student');
    }
  };

  const handleDeleteClass = async () => {
    try {
      await axios.delete(`${BASE_URL}/subjectlevelclass/${classId}`);
      navigate('/classes'); // Redirect to classes page after deletion
      toast.success('Class deleted successfully!');
    } catch (error) {
      console.error('Error deleting class:', error);
      toast.error('Error deleting class');
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    filterStudents(e.target.value, genderFilter);
  };

  const handleGenderFilter = (e) => {
    setGenderFilter(e.target.value);
    filterStudents(searchQuery, e.target.value);
  };

  const filterStudents = (searchQuery, genderFilter) => {
    let filtered = students;
    if (searchQuery) {
      filtered = filtered.filter(
        (student) =>
          student.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          student.Surname.toLowerCase().includes(searchQuery.toLowerCase()) ||
          student.RegNumber.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (genderFilter !== 'All') {
      filtered = filtered.filter((student) => student.Gender === genderFilter);
    }
    setFilteredStudents(filtered);
  };

  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredStudents);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');
    XLSX.writeFile(workbook, `Class_${classId}_Students.xlsx`);
  };

  return (
    <div className="p-4 bg-white rounded shadow-md">
      <div className="flex gap-4">
        <button
          className={`px-4 py-2 rounded ${activeSection === 'classInfo' ? 'bg-[#BB005A] text-white' : 'bg-gray-200 text-black'}`}
          onClick={() => setActiveSection('classInfo')}
        >
          Class Info
        </button>
        <button
          className={`px-4 py-2 rounded ${activeSection === 'students' ? 'bg-[#BB005A] text-white' : 'bg-gray-200 text-black'}`}
          onClick={() => {
            setActiveSection('students');
            fetchStudents();
          }}
        >
          Students
        </button>
      </div>
      <ToastContainer />
      {activeSection === 'classInfo' && subjectClass && (
        <div className="mt-4">
          <h3 className="text-xl font-semibold border-b pb-2 mb-4 text-blue-600">Subject Class Information</h3>
          <p><strong>Class ID:</strong> {subjectClass.ClassID}</p>
          <p><strong>Class Name:</strong> {subjectClass.ClassName}</p>
          <p><strong>Subject:</strong> {subjectClass.Subject}</p>
          <p><strong>Teacher:</strong> {subjectClass.Teacher}</p>
          <p><strong>Form:</strong> {subjectClass.Form}</p>
          <p><strong>Class Type:</strong> {subjectClass.ClassType}</p>
          <p><strong>Grade Level Class:</strong> {subjectClass.gradelevelclass}</p>
          <p><strong>Teacher Name:</strong> {subjectClass.TeacherName}</p>
          <div className="mt-4 flex justify-end">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={() => navigate(`/edit-subjectclass/${classId}`)}
            >
              Edit Class
            </button>
           
          </div>
        </div>
      )}

      {activeSection === 'students' && (
        <div className="mt-4">
          <h3 className="text-xl font-semibold border-b pb-2 mb-4">Total Students {filteredStudents.length}</h3>
          <div className="flex justify-between mb-4">
            <div className="flex gap-2">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by name, surname, or reg number"
                className="border rounded px-4 py-2"
              />
              <select value={genderFilter} onChange={handleGenderFilter} className="border rounded px-4 py-2">
                <option value="All">All</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              onClick={downloadExcel}
            >
              Download Excel
            </button>
          </div>
          <div className="flex justify-end mb-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={() => setShowAddModal(true)}
            >
              Add Student
            </button>
          </div>
          <table className="min-w-full bg-white">
            <thead>
              <tr className="bg-[#BB005A]">
                <th className="py-2 px-4 text-left text-white">Name</th>
                <th className="py-2 px-4 text-left text-white">Surname</th>
                <th className="py-2 px-4 text-left text-white">Reg Number</th>
                <th className="py-2 px-4 text-left text-white">Gender</th>
                <th className="py-2 px-4 text-left text-white">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentStudents.map((student, index) => (
                <tr key={index} className="border-t">
                  <td className="py-2 px-4">{student.Name}</td>
                  <td className="py-2 px-4">{student.Surname}</td>
                  <td className="py-2 px-4">{student.RegNumber}</td>
                  <td className="py-2 px-4">{student.Gender}</td>
                  <td className="py-2 px-4">
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                      onClick={() => {
                        setStudentToDelete(student);
                        setShowDeleteModal(true);
                      }}
                    >
                      Remove from Class
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-between mt-4">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-300 text-black px-4 py-2 rounded"
            >
              Previous
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastStudent >= filteredStudents.length}
              className="bg-gray-300 text-black px-4 py-2 rounded"
            >
              Next
            </button>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-md">
            <h4 className="text-xl font-semibold mb-4">Confirm Deletion</h4>
            <p>Are you sure you want to remove {studentToDelete.Name} from this class?</p>
            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={handleRemoveStudent}
              >
                Yes, Remove
              </button>
            </div>
          </div>
        </div>
      )}

      {showAddModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-md">
            <h4 className="text-xl font-semibold mb-4">Add Student</h4>
            <div>
              <label className="block mb-2">Reg Number:</label>
              <input
                type="text"
                value={regNumber}
                onChange={(e) => setRegNumber(e.target.value)}
                className="border rounded px-4 py-2 mb-4 w-full"
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={() => setShowAddModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleAddStudent}
              >
                Add Student
              </button>
            </div>
          </div>
        </div>
      )}

      {showClassDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-md">
            <h4 className="text-xl font-semibold mb-4">Confirm Class Deletion</h4>
            <p>Are you sure you want to delete this class?</p>
            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                onClick={() => setShowClassDeleteModal(false)}
              >
                Cancel
              </button>
             
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewSubjectClass;
