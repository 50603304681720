import React, { useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

Chart.register(...registerables);

const Dashboard = () => {
  const [financialData, setFinancialData] = useState([]);
  const [totalThisMonth, setTotalThisMonth] = useState(0);
  const [studentData, setStudentData] = useState({ totalStudents: 0, genderStats: [] });
  const [announcement, setAnnouncement] = useState(null);
  const [employeeData, setEmployeeData] = useState({ totalEmployees: 0, genderStats: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);

      const financialsResponse = await axios.get(`${BASE_URL}/dashboard/financials`);
      const studentsResponse = await axios.get(`${BASE_URL}/dashboard/students`);
      const announcementResponse = await axios.get(`${BASE_URL}/dashboard/announcement`);
      const employeesResponse = await axios.get(`${BASE_URL}/dashboard/employees`);

      setFinancialData(financialsResponse.data.monthlyTotals || []);
      setTotalThisMonth(financialsResponse.data.currentMonthTotal || 0);
      setStudentData(studentsResponse.data || { totalStudents: 0, genderStats: [] });
      setAnnouncement(announcementResponse.data || null);
      setEmployeeData(employeesResponse.data || { totalEmployees: 0, genderStats: [] });
    } catch (error) {
      toast.error('Failed to fetch dashboard data');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p className="text-center text-gray-600">Loading...</p>;
  }

  return (
    <div className="p-6 space-y-6">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Dashboard</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
          <h3 className="text-xl font-semibold mb-4 text-gray-700">Financials</h3>
          <Line
            data={{
              labels: financialData.map((item) => item.month),
              datasets: [
                {
                  label: 'Total Amount',
                  data: financialData.map((item) => item.total),
                  borderColor: 'rgba(54, 162, 235, 1)',
                  backgroundColor: 'rgba(54, 162, 235, 0.5)',
                  fill: true,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: '#333', 
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    color: '#333', 
                  },
                },
                x: {
                  ticks: {
                    color: '#333', 
                  },
                },
              },
            }}
          />
          <p className="mt-4 text-lg text-gray-700">Total Received This Month: ${totalThisMonth}</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
          <h3 className="text-xl font-semibold mb-4 text-gray-700">Students</h3>
          <p className="text-lg mb-4 text-gray-700">Total Students: {studentData.totalStudents}</p>
          <Pie
            data={{
              labels: studentData.genderStats.map((item) => item.Gender),
              datasets: [
                {
                  label: 'Students',
                  data: studentData.genderStats.map((item) => item.count),
                  backgroundColor: ['rgba(255, 99, 132, 0.8)', 'rgba(54, 162, 235, 0.8)'],
                  borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: '#333', // Change legend text color
                  },
                },
              },
            }}
            height={120}
            width={120}
          />
        </div>

        {/* Announcements Section */}
        <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
          {announcement && (
            <>
              <h3 className="text-xl font-semibold mb-4 text-gray-700">Latest Announcement</h3>
              <textarea
                className="w-full p-3 border rounded text-gray-800 bg-gray-50 h-36 resize-none focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={announcement.Description}
                readOnly
              />
              <Link
                to="/announcements"
                className="mt-4 inline-block text-blue-500 underline hover:text-blue-700 transition"
              >
                View All Announcements
              </Link>
            </>
          )}
        </div>

        {/* Employees Section */}
        <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
          <h3 className="text-xl font-semibold mb-4 text-gray-700">Employees</h3>
          <p className="text-lg text-gray-700">Total Employees: {employeeData.totalEmployees}</p>
          <Bar
            data={{
              labels: employeeData.genderStats.map((item) => item.Gender),
              datasets: [
                {
                  label: 'Employees',
                  data: employeeData.genderStats.map((item) => item.count),
                  backgroundColor: ['rgba(75, 192, 192, 0.8)', 'rgba(255, 159, 64, 0.8)'],
                  borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 159, 64, 1)'],
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    color: '#333', // Change legend text color
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    color: '#333', // Change y-axis text color
                  },
                },
                x: {
                  ticks: {
                    color: '#333', // Change x-axis text color
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
