import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Declare the BASE_URL at the top for API and document downloads
const BASE_URL = 'https://oxfordstudycenterapp.site';

const AssessmentResults = () => {
  const { assessmentId } = useParams(); // Get the assessment ID from the URL
  const [assessmentInfo, setAssessmentInfo] = useState(null);
  const [studentResults, setStudentResults] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch assessment info and results
  const fetchAssessmentResults = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/assessments-results/get-assessment-results/${assessmentId}`);
      const { assessmentInfo, studentResults } = response.data;
      
      setAssessmentInfo(assessmentInfo);
      setStudentResults(studentResults);
    } catch (error) {
      console.error('Error fetching assessment results:', error);
      toast.error('Failed to load assessment results.');
    } finally {
      setLoading(false);
    }
  };

  // Function to download the document
  const handleDownloadDocument = (document) => {
    if (document) {
      const downloadUrl = `${BASE_URL}/assessments/download-document/${document}`; // Combine BASE_URL with document name
      window.open(downloadUrl, '_blank');
    } else {
      toast.error('No document available for download.');
    }
  };

  useEffect(() => {
    fetchAssessmentResults();
  }, [assessmentId]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Assessment Results</h1>

      {/* Loading Indicator */}
      {loading && <p>Loading assessment results...</p>}

      {/* Assessment Info */}
      {assessmentInfo && (
        <div className="bg-white shadow-md rounded p-4 mb-4">
          <h2 className="text-xl font-bold mb-4">{assessmentInfo.AssessmentName}</h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full sm:w-1/2 px-2 mb-4">
              <p><strong>Created On:</strong> {new Date(assessmentInfo.DateCreated).toLocaleDateString()}</p>
            </div>
            <div className="w-full sm:w-1/2 px-2 mb-4">
              <p><strong>Due Date:</strong> {new Date(assessmentInfo.DateDue).toLocaleDateString()}</p>
            </div>
            <div className="w-full sm:w-1/2 px-2 mb-4">
              <p><strong>Class:</strong> {assessmentInfo.ClassName} (Form {assessmentInfo.Form}, Term {assessmentInfo.Term}, {assessmentInfo.Year})</p>
            </div>
            <div className="w-full sm:w-1/2 px-2 mb-4">
              <p><strong>Teacher:</strong> {`${assessmentInfo.EmployeeName} ${assessmentInfo.EmployeeSurname}`}</p>
            </div>
          </div>

          {/* Download Document Button */}
          {assessmentInfo.Document && (
            <button
              onClick={() => handleDownloadDocument(assessmentInfo.Document)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mt-4"
            >
              Download Document
            </button>
          )}
        </div>
      )}

      {/* No Assessment Info Found */}
      {!loading && !assessmentInfo && (
        <p>No assessment information found.</p>
      )}

      {/* Student Results */}
      {studentResults.length > 0 ? (
        <table className="min-w-full bg-white shadow-md rounded">
          <thead>
            <tr>
              <th className="py-2 text-start px-4 border-b">Reg Number</th>
              <th className="py-2 text-start px-4 border-b">Student Name</th>
              <th className="py-2 text-start px-4 border-b">Mark</th>
            </tr>
          </thead>
          <tbody>
            {studentResults.map(result => (
              <tr key={result.resultId}>
                <td className="py-2 px-4 border-b">{result.regNumber}</td>
                <td className="py-2 px-4 border-b">{`${result.studentName} ${result.studentSurname}`}</td>
                <td className="py-2 px-4 border-b">{result.mark}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No results available for this assessment.</p>
      )}

      <ToastContainer />
    </div>
  );
};

export default AssessmentResults;
