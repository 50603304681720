// src/pages/StudentRecords.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import { format } from 'date-fns';

const StudentRecords = () => {
    const [regNumber, setRegNumber] = useState('');
    const [student, setStudent] = useState(null);
    const [balances, setBalances] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [searchParams, setSearchParams] = useState({
        term: '',
        year: '',
        paynow_reference: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (regNumber) {
            fetchStudentRecords();
        }
    }, [regNumber]);

    const fetchStudentRecords = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`${BASE_URL}/payments/student/${regNumber}`);
            setStudent(response.data.student);
            setBalances(response.data.balances);
            setTransactions(response.data.transactions);
        } catch (err) {
            console.error('Error fetching student records:', err);
            setError('Failed to fetch student records.');
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prevParams => ({
            ...prevParams,
            [name]: value
        }));
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/payments/student/${regNumber}/search-transactions`, {
                params: searchParams
            });
            setTransactions(response.data.transactions);
        } catch (err) {
            console.error('Error searching transactions:', err);
            setError('Failed to search transactions.');
        } finally {
            setLoading(false);
        }
    };

    const openModal = (transaction) => {
        setSelectedTransaction(transaction);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedTransaction(null);
        setShowModal(false);
    };

    return (
        <div className="p-4">
            <h2 className="text-xl text-gray-700 font-bold mb-4">Student Records</h2>

            {/* Registration Number Input and Search Button */}
            <div className="flex items-center justify-between px-4 mb-4">
                <input
                    type="text"
                    value={regNumber}
                    onChange={(e) => setRegNumber(e.target.value)}
                    className="mt-1 p-2 border rounded w-full max-w-xs mr-2"
                    placeholder="Enter registration number"
                    style={{ borderColor: '#BB005A' }}
                />
                <button
                    onClick={fetchStudentRecords}
                    className="bg-pink-600 text-white px-4 mr-5 py-2 rounded"
                    style={{ backgroundColor: '#BB005A' }}
                >
                    Search
                </button>
            </div>

            {loading && (
                <div className="flex justify-center items-center">
                    <div className="spinner" style={{ borderColor: '#BB005A transparent transparent transparent' }}></div>
                </div>
            )}

            {error && <div className="text-red-500">{error}</div>}

            {student && (
                <div className="mb-4 p-6 bg-white rounded shadow-md">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <p className="text-gray-500"><strong>Reg Number:</strong></p>
                            <p>{student.RegNumber}</p>
                        </div>
                        <div>
                            <p className="text-gray-500"><strong>Name:</strong></p>
                            <p>{student.Name} {student.Surname}</p>
                        </div>
                        <div>
                            <p className="text-gray-500"><strong>Medical Aid:</strong></p>
                            <p>{student.HasMedicalAid ? 'Yes' : 'No'}</p>
                        </div>
                        {balances.length > 0 && balances.map(balance => (
                            <React.Fragment key={balance.id}>
                                <div>
                                    <p className="text-gray-500"><strong>Term:</strong></p>
                                    <p>{balance.term}</p>
                                </div>
                                <div>
                                    <p className="text-gray-500"><strong>Year:</strong></p>
                                    <p>{balance.year}</p>
                                </div>
                                <div>
                                    <p className="text-gray-500"><strong>Balance:</strong></p>
                                    <p>{balance.balance} ({balance.balance_type})</p>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            )}

            <div className="mb-4 p-4 bg-white rounded shadow-md">
                <h3 className="font-semibold text-gray-700 text-lg mb-2">Search Transactions</h3>
                <form onSubmit={handleSearch} className="grid grid-cols-1 md:grid-cols-5 gap-4 items-end">
                    <div>
                        <label className="block text-gray-700">Term</label>
                        <select
                            name="term"
                            value={searchParams.term}
                            onChange={handleSearchChange}
                            className="mt-1 p-2 border rounded w-full"
                        >
                            <option value="">All Terms</option>
                            <option value="1">Term 1</option>
                            <option value="2">Term 2</option>
                            <option value="3">Term 3</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700">Year</label>
                        <input
                            type="number"
                            name="year"
                            value={searchParams.year}
                            onChange={handleSearchChange}
                            className="mt-1 p-2 border rounded w-full"
                            placeholder="e.g., 2024"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Txn Reference</label>
                        <input
                            type="text"
                            name="paynow_reference"
                            value={searchParams.paynow_reference}
                            onChange={handleSearchChange}
                            className="mt-1 p-2 border rounded w-full"
                            placeholder="Reference"
                        />
                    </div>
                    <div className="col-span-2 flex justify-end">
                        <button
                            type="submit"
                            className="bg-pink-600 text-white px-4 py-2 rounded"
                            style={{ backgroundColor: '#BB005A' }}
                        >
                            Search Transactions
                        </button>
                    </div>
                </form>
            </div>

            {transactions.length > 0 ? (
                <div className="p-4 bg-white rounded shadow-md">
                    <h3 className="text-lg text-gray-700 font-semibold mb-2">Transactions</h3>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border">
                            <thead style={{ backgroundColor: '#BB005A', color: 'white' }}>
                                <tr>
                                    <th className="px-4 py-2 border">Date</th>
                                    <th className="px-4 py-2 border">Txn Ref</th>
                                    <th className="px-4 py-2 border">Received Amount</th>
                                    <th className="px-4 py-2 border">Reported Amount</th>
                                    <th className="px-4 py-2 border">Currency</th>
                                    <th className="px-4 py-2 border">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map(transaction => (
                                    <tr key={transaction.id} className="hover:bg-pink-100">
                                        <td className="px-4 py-2 border">{format(new Date(transaction.created_at), 'yyyy-MM-dd HH:mm')}</td>
                                        <td className="px-4 py-2 border">{transaction.paynow_reference}</td>
                                        <td className="px-4 py-2 border">{transaction.received_amount}</td>
                                        <td className="px-4 py-2 border">{transaction.reported_amount}</td>
                                        <td className="px-4 py-2 border">{transaction.currency}</td>
                                        <td className="px-4 py-2 border">
                                            <button
                                                className="bg-blue-500 text-white px-2 py-1 rounded"
                                                onClick={() => openModal(transaction)}
                                            >
                                                View Details
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : !loading && (
                <div className="text-center text-gray-500 mt-6">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-16 w-16 mx-auto mb-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 5l7 7-7 7"
                        />
                    </svg>
                    <p>No transaction records at the moment.</p>
                </div>
            )}

            {/* Modal for Transaction Details */}
            {showModal && selectedTransaction && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
                        <h3 className="text-lg font-bold mb-4">Transaction Details</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <strong>Reg Number:</strong> {selectedTransaction.reg_number}
                            </div>
                            <div>
                                <strong>Form:</strong> {selectedTransaction.form}
                            </div>
                            <div>
                                <strong>Term:</strong> {selectedTransaction.term}
                            </div>
                            <div>
                                <strong>Year:</strong> {selectedTransaction.year}
                            </div>
                            <div>
                                <strong>Txn Ref:</strong> {selectedTransaction.paynow_reference}
                            </div>
                            <div>
                                <strong>Date:</strong> {format(new Date(selectedTransaction.created_at), 'yyyy-MM-dd HH:mm')}
                            </div>
                            <div>
                                <strong>Received Amount:</strong> {selectedTransaction.received_amount}
                            </div>
                            <div>
                                <strong>Reported Amount:</strong> {selectedTransaction.reported_amount}
                            </div>
                            <div>
                                <strong>Currency:</strong> {selectedTransaction.currency}
                            </div>
                            <div className="col-span-2">
                                <strong>Payment Method:</strong> {selectedTransaction.payment_method}
                            </div>
                            <div className="col-span-2">
                                <strong>Status:</strong> {selectedTransaction.status}
                            </div>
                        </div>
                        <div className="mt-4 flex justify-end">
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default StudentRecords;
