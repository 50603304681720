import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Financials from './pages/Financials';
import Login from './pages/Login';
import Students from './pages/Students';
import CreateStudent from './pages/CreateStudent';
import Sidebar from './components/Sidebar';
import ViewStudent from './pages/ViewStudent';
import AddEmployee from './pages/AddEmployee';
import EditEmployee from './pages/EditEmployee';
import EmployeesPage from './pages/EmployeesPage';
import Classes from './pages/Classes';
import AddClass from './pages/AddClass';
import Viewclass from './pages/Viewclass';
import ViewSubjectClass from './pages/ViewSubjectClass';
import EditSubjectLevelClass from './pages/EditSubjectLevelClass';
import Results from './pages/Results';
import ViewClassResults from './pages/ViewClassResults';
import ViewEmployee from './pages/ViewEmployee';
import CreatePayslip from './pages/CreatePayslip';
import Announcements from './pages/Announcements';
import Editstudent from './pages/Editstudent';
import Settings from './pages/Settings';
import AddTimetablePage from './pages/AddTimetablePage';
import ViewTimetablePage from './pages/ViewTimetablePage';
import EditTimetablePage from './pages/EditTimetablePage';
import CreateInvoice from './pages/CreateInvoice';
import ViewInvoices from './pages/ViewInvoices';
import EditInvoice from './pages/EditInvoice';
import MakePayment from './pages/MakePayment'
import Payments from './pages/Payments'
import StudentRecords from './pages/StudentRecords';
import TempPayments from './pages/TempPayments'
import EditPayment from './pages/EditPayment';
import Dashboard from './pages/Dashboard';
import EditGradeLevelClass from './pages/EditGradelevelclass';
import AddGradeLevelClass from './pages/AddGradeLevelClass';
import AddSubjectLevelClass from './pages/AddSubjectLevelClass';
import NewStudents from './pages/NewStudents';
import RegistrationFees from './pages/RegistrationFees';
import SchemeBooks from './pages/SchemeBooks';
import UpdateBalance from './pages/UpdateBalance';
import Lessons from './pages/Lessons'
import Assessments from './pages/Assessments';
import AssessmentResults from './pages/AssessmentResults';
const App = () => {
  const location = useLocation();
  const showSidebar = location.pathname !== '/';

  return (
    <AuthProvider>
      <div className="flex">
        {showSidebar && <Sidebar />}
        <div className={showSidebar ? "ml-64 flex-1" : "flex-1"}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/financials"
              element={
                <PrivateRoute>
                  <Financials />
                </PrivateRoute>
              }
            />
            <Route
              path="/students"
              element={
                <PrivateRoute>
                  <Students />
                </PrivateRoute>
              }
            />
            <Route
              path="/lesson-plans"
              element={
                <PrivateRoute>
                  <Lessons />
                </PrivateRoute>
              }
            />
            <Route
              path="/assessments"
              element={
                <PrivateRoute>
                  <Assessments />
                </PrivateRoute>
              }
            />
            <Route
              path="/assessment-results/:assessmentId"
              element={
                <PrivateRoute>
                  <AssessmentResults />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-student"
              element={
                <PrivateRoute>
                  <CreateStudent />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-student/:regnumber"
              element={
                <PrivateRoute>
                  <ViewStudent />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-employee"
              element={
                <PrivateRoute>
                  <AddEmployee />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-employee/:employeeId"
              element={
                <PrivateRoute>
                  <EditEmployee />
                </PrivateRoute>
              }
            />
            <Route
              path="/employees"
              element={
                <PrivateRoute>
                  <EmployeesPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/results"
              element={
                <PrivateRoute>
                  <Results />
                </PrivateRoute>
              }
            />
            <Route
              path="/classes"
              element={
                <PrivateRoute>
                  <Classes />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-class"
              element={
                <PrivateRoute>
                  <AddClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-class"
              element={
                <PrivateRoute>
                  <EditSubjectLevelClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-class/:classId"
              element={
                <PrivateRoute>
                  <Viewclass />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-classresults/:classID/:form"
              element={
                <PrivateRoute>
                  <ViewClassResults />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-subjectclass/:classId"
              element={
                <PrivateRoute>
                  <ViewSubjectClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-subjectclass/:classId"
              element={
                <PrivateRoute>
                  <EditSubjectLevelClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-employee/:EmployeeID"
              element={
                <PrivateRoute>
                  <ViewEmployee />
                </PrivateRoute>
              }
            />
            <Route
              path="/announcements"
              element={
                <PrivateRoute>
                  <Announcements />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-payslip"
              element={
                <PrivateRoute>
                  <CreatePayslip />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-student/:regnumber"
              element={
                <PrivateRoute>
                  <Editstudent />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            {/* Timetable Routes */}
            <Route
              path="/add-timetable"
              element={
                <PrivateRoute>
                  <AddTimetablePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-timetable"
              element={
                <PrivateRoute>
                  <ViewTimetablePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-timetable"
              element={
                <PrivateRoute>
                  <EditTimetablePage />
                </PrivateRoute>
              }
            />
            {/* Invoice Routes */}
            <Route
              path="/create-invoice"
              element={
                <PrivateRoute>
                  <CreateInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-invoices"
              element={
                <PrivateRoute>
                  <ViewInvoices />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-invoice/:id"
              element={
                <PrivateRoute>
                  <EditInvoice />
                </PrivateRoute>
              }
            />
            <Route
              path="/make-payment"
              element={
                <PrivateRoute>
                  <MakePayment />
                </PrivateRoute>
              }
            />
            <Route
              path="/payments"
              element={
                <PrivateRoute>
                  <Payments />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-records"
              element={
                <PrivateRoute>
                  <StudentRecords />
                </PrivateRoute>
              }
            />
            <Route
              path="/temp-payments"
              element={
                <PrivateRoute>
                  <TempPayments />
                </PrivateRoute>
              }
            />
            <Route
              path="/payments/edit/:id"
              element={
                <PrivateRoute>
                  <EditPayment />
                </PrivateRoute>
              }
            />
            <Route
              path="/edit-gradelevelclass/:classId"
              element={
                <PrivateRoute>
                  <EditGradeLevelClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-gradelevelclass"
              element={
                <PrivateRoute>
                  <AddGradeLevelClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/add-subjectlevelclass"
              element={
                <PrivateRoute>
                  <AddSubjectLevelClass />
                </PrivateRoute>
              }
            />
            <Route
              path="/new-students"
              element={
                <PrivateRoute>
                  <NewStudents />
                </PrivateRoute>
              }
            />
            <Route
              path="/registration-fees"
              element={
                <PrivateRoute>
                  <RegistrationFees />
                </PrivateRoute>
              }
            />
            <Route
              path="/scheme-books"
              element={
                <PrivateRoute>
                  <SchemeBooks />
                </PrivateRoute>
              }
            />
            <Route
              path="/update-balance"
              element={
                <PrivateRoute>
                  <UpdateBalance />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
