import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../context/api';

const departments = ['Teaching', 'Human Resources', 'Finance', 'Administration'];
const roles = ['Teacher', 'Service Worker', 'Bursar', 'Headmaster', 'Groundsman', 'Other'];

const generateEmployeeNumber = () => {
  const randomNumber = Math.floor(1000 + Math.random() * 9000);
  const randomLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
  return `V${randomNumber}${randomLetter}`;
};

const AddEmployee = () => {
  const [employee, setEmployee] = useState({
    Department: '',
    Role: '',
    Name: '',
    Surname: '',
    DateOfBirth: '',
    Address: '',
    PhoneNumber: '',
    NationalIDNumber: '',
    Gender: '',
    EmployeeNumber: '',
    Password: ''
  });

  const [bankDetails, setBankDetails] = useState([
    { BankName: '', AccountNumber: '', Currency: '' }
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const handleBankChange = (e, index) => {
    const { name, value } = e.target;
    const updatedBankDetails = [...bankDetails];
    updatedBankDetails[index][name] = value;
    setBankDetails(updatedBankDetails);
  };

  const handleAddBankDetail = () => {
    setBankDetails([...bankDetails, { BankName: '', AccountNumber: '', Currency: '' }]);
  };

  const handleRemoveBankDetail = (index) => {
    const updatedBankDetails = bankDetails.filter((_, i) => i !== index);
    setBankDetails(updatedBankDetails);
  };

  const handleGenerateEmployeeNumber = () => {
    const newEmployeeNumber = generateEmployeeNumber();
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      EmployeeNumber: newEmployeeNumber,
      Password: newEmployeeNumber,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Construct the payload
    const payload = {
        employeeData: {
            Department: employee.Department,
            Role: employee.Role,
            Name: employee.Name,
            Surname: employee.Surname,
            DateOfBirth: employee.DateOfBirth,
            Address: employee.Address,
            PhoneNumber: employee.PhoneNumber,
            NationalIDNumber: employee.NationalIDNumber,
            Gender: employee.Gender,
            EmployeeNumber: employee.EmployeeNumber,
            Password: employee.Password
        },
        bankDetails: bankDetails // Make sure you're using the correct state for bank details
    };

    try {
        const response = await axios.post(`${BASE_URL}/createemployee`, payload);
        if (response.status === 200) {
            toast.success('Employee created successfully');
            setEmployee({
                Department: '',
                Role: '',
                Name: '',
                Surname: '',
                DateOfBirth: '',
                Address: '',
                PhoneNumber: '',
                NationalIDNumber: '',
                Gender: '',
                EmployeeNumber: '',
                Password: ''
            });
            setBankDetails([{ BankName: '', AccountNumber: '', Currency: '' }]);
        }
    } catch (error) {
        toast.error('Error creating employee');
    }
};

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Add Employee</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block">Department</label>
            <select
              name="Department"
              value={employee.Department}
              onChange={handleChange}
              className="p-2 border rounded w-full"
              required
            >
              <option value="" disabled>Select Department</option>
              {departments.map((dept) => (
                <option key={dept} value={dept}>{dept}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block">Role</label>
            <select
              name="Role"
              value={employee.Role}
              onChange={handleChange}
              className="p-2 border rounded w-full"
              required
            >
              <option value="" disabled>Select Role</option>
              {roles.map((role) => (
                <option key={role} value={role}>{role}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block">Name</label>
            <input
              type="text"
              name="Name"
              value={employee.Name}
              onChange={handleChange}
              className="p-2 border rounded w-full"
              required
            />
          </div>
          <div>
            <label className="block">Surname</label>
            <input
              type="text"
              name="Surname"
              value={employee.Surname}
              onChange={handleChange}
              className="p-2 border rounded w-full"
              required
            />
          </div>
          <div>
            <label className="block">Date of Birth</label>
            <input
              type="date"
              name="DateOfBirth"
              value={employee.DateOfBirth}
              onChange={handleChange}
              className="p-2 border rounded w-full"
              required
            />
          </div>
          <div>
            <label className="block">Address</label>
            <input
              type="text"
              name="Address"
              value={employee.Address}
              onChange={handleChange}
              className="p-2 border rounded w-full"
              required
            />
          </div>
          <div>
            <label className="block">Phone Number</label>
            <input
              type="text"
              name="PhoneNumber"
              value={employee.PhoneNumber}
              onChange={handleChange}
              className="p-2 border rounded w-full"
              required
            />
          </div>
          <div>
            <label className="block">National ID Number</label>
            <input
              type="text"
              name="NationalIDNumber"
              value={employee.NationalIDNumber}
              onChange={handleChange}
              className="p-2 border rounded w-full"
              required
            />
          </div>
          <div>
            <label className="block">Gender</label>
            <select
              name="Gender"
              value={employee.Gender}
              onChange={handleChange}
              className="p-2 border rounded w-full"
              required
            >
              <option value="" disabled>Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block">Employee Number</label>
            <div className="flex items-center">
              <input
                type="text"
                name="EmployeeNumber"
                value={employee.EmployeeNumber}
                onChange={handleChange}
                className="p-2 border rounded w-full mr-2"
                required
                readOnly
              />
              <button
                type="button"
                onClick={handleGenerateEmployeeNumber}
                className="p-2 bg-blue-500 text-white rounded"
              >
                Generate
              </button>
            </div>
          </div>
          <div>
            <label className="block">Password</label>
            <input
              type="text"
              name="Password"
              value={employee.Password}
              onChange={handleChange}
              className="p-2 border rounded w-full"
              required
              readOnly
            />
          </div>
        </div>

        {/* Banking Information */}
        <div>
          <label className="block mb-2 font-bold">Banking Information:</label>
          {bankDetails.map((bankDetail, index) => (
            <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-4 items-end mb-3">
              {/* Bank Name Field */}
              <div>
                <label className="block mb-1 text-gray-700">Bank Name</label>
                <input
                  type="text"
                  name="BankName"
                  value={bankDetail.BankName}
                  onChange={(e) => handleBankChange(e, index)}
                  placeholder="Bank Name"
                  className="p-2 border rounded w-full"
                  required
                />
              </div>

              {/* Account Number Field */}
              <div>
                <label className="block mb-1 text-gray-700">Account Number</label>
                <input
                  type="text"
                  name="AccountNumber"
                  value={bankDetail.AccountNumber}
                  onChange={(e) => handleBankChange(e, index)}
                  placeholder="Account Number"
                  className="p-2 border rounded w-full"
                  required
                />
              </div>

              {/* Currency Field */}
              <div>
                <label className="block mb-1 text-gray-700">Currency</label>
                <input
                  type="text"
                  name="Currency"
                  value={bankDetail.Currency}
                  onChange={(e) => handleBankChange(e, index)}
                  placeholder="Currency"
                  className="p-2 border rounded w-full"
                  required
                />
              </div>

              {/* Remove Button */}
              {index > 0 && (
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => handleRemoveBankDetail(index)}
                    className="p-2 bg-red-500 text-white rounded"
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          ))}

          <button
            type="button"
            onClick={handleAddBankDetail}
            className="p-2 bg-green-500 text-white rounded"
          >
            Add Banking Detail
          </button>
        </div>



        <button
          type="submit"
          className="p-2 bg-green-500 text-white rounded"
        >
          Add Employee
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddEmployee;
