import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../context/api';
import { getMonth, getYear, format, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay } from 'date-fns';

const ViewStudent = () => {
  const { regnumber } = useParams();
  const navigate = useNavigate();
  const [student, setStudent] = useState(null);
  const [attendance, setAttendance] = useState([]);
  const [subjectResults, setSubjectResults] = useState([]);
  const [classPosition, setClassPosition] = useState(null);
  const [formPosition, setFormPosition] = useState(null);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedTab, setSelectedTab] = useState('personalInfo');
  const [term, setTerm] = useState('');
  const [year, setYear] = useState('');
  const [form, setForm] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    fetchStudent();
    fetchClasses();
  }, [regnumber]);

  const fetchStudent = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/getstudent/${regnumber}`);
      setStudent(response.data);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching student:', error);
    }
  };

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/editgradelevelclass`);
      setClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchAttendance = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/attendance/${regnumber}/${term}/${year}`);
      setAttendance(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching attendance:', error);
    }
  };

  const fetchResults = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/getsubjectresults`, {
        termID: term,
        year,
        studentRegNumber: regnumber,
        classID: selectedClass,
        form,
      });
      console.log('Results fetched:', response.data);
      setSubjectResults(Array.isArray(response.data.subjectResults) ? response.data.subjectResults : []);
      setClassPosition(response.data.classPosition);
      setFormPosition(response.data.formPosition);
    } catch (error) {
      console.error('Error fetching results:', error);
      setSubjectResults([]);
      setClassPosition(null);
      setFormPosition(null);
    }
  };

  const handleEdit = () => {
    navigate(`/edit-student/${regnumber}`);
  };

  const handleDelete = async () => {
    try {
      await axios.put(`${BASE_URL}/createstudent/delete-student/${regnumber}`);
      navigate('/students');
    } catch (error) {
      console.error('Error deleting student:', error);
    }
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    try {
      await axios.post(`${BASE_URL}/students-auth/student-change-password`, { newPassword, regNumber: regnumber });
      alert('Password updated successfully');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Error updating password');
    }
  };


  if (!student) {
    return <div>Loading...</div>;
  }

  const renderPersonalInfo = () => (
    <div className="p-4 bg-white rounded shadow-md">
      <h3 className="text-xl font-semibold border-b pb-2 mb-4">Personal Info</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <p><span className="font-semibold">Forenames:</span> {student.student.Name}</p>
          <p><span className="font-semibold">D.O.B:</span> {new Date(student.student.DateOfBirth).toLocaleDateString()}</p>
          <p><span className="font-semibold">Class:</span> Form 1 Maroon</p>
          <p><span className="font-semibold">Address:</span> {student.student.Address}</p>
        </div>
        <div>
          <p><span className="font-semibold">Surname:</span> {student.student.Surname}</p>
          <p><span className="font-semibold">National ID:</span> {student.student.NationalIDNumber}</p>
          <p><span className="font-semibold">Gender:</span> {student.student.Gender}</p>
          <p><span className="font-semibold">Medical Aid:</span> {student.student.HasMedicalAid === 1 ? "Yes" : "No"}</p>
        </div>
      </div>

      <h4 className="text-lg font-semibold border-b pb-2 mt-6 mb-4">Guardians Info</h4>
      {student.guardians.map((guardian, index) => (
        <div key={index} className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
          <div>
            <p><span className="font-semibold">Forenames:</span> {guardian.Name}</p>
            <p><span className="font-semibold">Phone No:</span> {guardian.PhoneNumber}</p>
            <p><span className="font-semibold">National ID:</span> {guardian.NationalIDNumber}</p>

          </div>
          <div>
            <p><span className="font-semibold">Surname:</span> {guardian.Surname}</p>
            <p><span className="font-semibold">Address:</span> {guardian.Address}</p>
          </div>
        </div>
      ))}
    </div>
  );

  const renderAttendance = () => {
    const presentDays = attendance.filter(entry => entry.Status === 'Present').length;
    const totalDays = attendance.length;
    const attendancePercentage = totalDays > 0 ? (presentDays / totalDays) * 100 : 0;

    const daysInMonth = eachDayOfInterval({
      start: startOfMonth(new Date(currentYear, currentMonth)),
      end: endOfMonth(new Date(currentYear, currentMonth)),
    });

    const handleMonthChange = (direction) => {
      const newMonth = currentMonth + direction;
      if (newMonth < 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      } else if (newMonth > 11) {
        setCurrentMonth(0);
        setCurrentYear(currentYear + 1);
      } else {
        setCurrentMonth(newMonth);
      }
    };

    const getAttendanceStatus = (date) => {
      const attendanceRecord = attendance.find(entry => isSameDay(new Date(entry.Date), date));
      return attendanceRecord ? attendanceRecord.Status : null;
    };

    return (
      <div className="p-4 bg-white rounded shadow-md">
        <h3 className="text-xl font-semibold border-b pb-2 mb-4">Attendance</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="term">Term</label>
            <select
              id="term"
              className="w-full px-3 py-2 border rounded-lg"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              required
            >
              <option value="">Select Term</option>
              {[1, 2, 3].map(t => (
                <option key={t} value={t}>Term {t}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 mb-2" htmlFor="year">Year</label>
            <input
              type="number"
              id="year"
              className="w-full px-3 py-2 border rounded-lg"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              required
            />
          </div>
        </div>
        <div className='flex items-center justify-center'>
          <button
            className=" bg-[#BB005A] text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 mb-4"
            onClick={fetchAttendance}
          >
            Fetch Attendance
          </button>
        </div>

        <div className="mb-4">
          <p><span className="font-semibold">Attendance Percentage:</span> {attendancePercentage.toFixed(2)}%</p>
        </div>
        <div className="flex justify-between items-center mb-4">
          <button className='bg-[#BB005A] text-white px-3 py-2 rounded-md' onClick={() => handleMonthChange(-1)}>&lt; Prev</button>
          <h4>{format(new Date(currentYear, currentMonth), 'MMMM yyyy')}</h4>
          <button className='bg-[#BB005A] text-white px-3 py-2 rounded-md' onClick={() => handleMonthChange(1)}>Next &gt;</button>
        </div>
        <div className="grid grid-cols-7 gap-4">
          {daysInMonth.map((day, index) => {
            const status = getAttendanceStatus(day);
            return (
              <div
                key={index}
                className={`p-4 rounded shadow-sm ${status === 'Present' ? 'bg-[#1D800D]' : status === 'Absent' ? 'bg-[#BF0202]' : 'bg-gray-100'}`}
              >
                <p><span className="font-semibold">{format(day, 'd')}</span></p>
                <p>{status ? (status === 'Present' ? '✓' : 'X') : ''}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };



  const renderResults = () => (
    <div className="p-4 bg-white rounded shadow-md">
      <h3 className="text-xl font-semibold border-b pb-2 mb-4">Results</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-gray-700 mb-2" htmlFor="term">Term</label>
          <select
            id="term"
            className="w-full px-3 py-2 border rounded-lg"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            required
          >
            <option value="">Select Term</option>
            {[1, 2, 3].map(t => (
              <option key={t} value={t}>Term {t}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-gray-700 mb-2" htmlFor="year">Year</label>
          <input
            type="number"
            id="year"
            className="w-full px-3 py-2 border rounded-lg"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            required
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-gray-700 mb-2" htmlFor="form">Form</label>
          <select
            id="form"
            className="w-full px-3 py-2 border rounded-lg"
            value={form}
            onChange={(e) => setForm(e.target.value)}
            required
          >
            <option value="">Select Form</option>
            {[1, 2, 3, 4, 5, 6].map(f => (
              <option key={f} value={f}>Form {f}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-gray-700 mb-2" htmlFor="class">Class</label>
          <select
            id="class"
            className="w-full px-3 py-2 border rounded-lg"
            value={selectedClass}
            onChange={(e) => setSelectedClass(e.target.value)}
            required
          >
            <option value="">Select Class</option>
            {classes.map(cls => (
              <option key={cls.ClassID} value={cls.ClassID}>{cls.ClassName}</option>
            ))}
          </select>
        </div>
      </div>
      <div className='w-full flex items-center justify-center mt-5'>
        <button
          className="flex items-center px-4 bg-pink-600 text-white py-2 rounded-lg hover:bg-pink-700 transition duration-300 mb-4"
          style={{ backgroundColor: '#BB005A' }}
          onClick={fetchResults}
        >
          Fetch Results
        </button>
      </div>

      <div>
        {Array.isArray(subjectResults) && subjectResults.length > 0 ? (
          <>
            <div className="mb-4 flex py-5 px-11 border justify-between">
              <p><span className="font-semibold">Class Position:</span> {classPosition}</p>
              <p><span className="font-semibold">Form Position:</span> {formPosition}</p>
            </div>
            <table className="min-w-full bg-white border border-gray-200 rounded-lg">
              <thead>
                <tr style={{ backgroundColor: '#BB005A' }} className="text-white">
                  <th className="py-2 px-4 text-start border-b">Subject</th>
                  <th className="py-2 px-4 text-start border-b">Final Mark</th>
                  <th className="py-2 px-4 text-start border-b">Grade</th>
                  <th className="py-2 px-4 text-start border-b">Comment</th>
                  <th className="py-2 px-4 text-start border-b">Paper Marks</th>
                </tr>
              </thead>
              <tbody>
                {subjectResults.map((result, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                    <td className="py-2 px-4 border-b">{result.SubjectName}</td>
                    <td className="py-2 px-4 border-b">{result.AverageMark}</td>
                    <td className="py-2 px-4 border-b">{result.Grade}</td>
                    <td className="py-2 px-4 border-b">{result.Comment}</td>
                    <td className="py-2 px-4 border-b">
                      {result.PaperMarks.map((paper, idx) => (
                        <div key={idx}>{paper.PaperName}: {paper.Mark}</div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>No results found.</p>
        )}
      </div>
    </div>
  );

  const renderSettings = () => (
    <div className="p-4 bg-white rounded shadow-md mt-5 mx-auto" style={{ maxWidth: '400px' }}>
      <h3 className="text-xl text-center mt-5 mb-8 font-semibold border-b pb-2 ">Change student password</h3>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2" htmlFor="newPassword">New Password</label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            id="newPassword"
            className="w-full px-3 py-2 border rounded-lg"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 px-3 py-2 text-gray-600"
            onClick={handleShowPasswordToggle}
          >
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2" htmlFor="confirmPassword">Confirm Password</label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            id="confirmPassword"
            className="w-full px-3 py-2 border rounded-lg"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 px-3 py-2 text-gray-600"
            onClick={handleShowPasswordToggle}
          >
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
      </div>
      <button
        className="w-full bg-[#BB005A] text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
        onClick={handleChangePassword}
      >
        Change Password
      </button>
    </div>
  );

  const renderDeleteModal = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <p>Are you sure you want to delete this student? This action cannot be undone.</p>
        <div className="flex justify-end mt-4">
          <button
            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 mr-2"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            onClick={handleDelete}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">{student.student.Name} {student.student.Surname}</h2>
        <div className="flex space-x-4">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={handleEdit}
          >
            Edit
          </button>
        </div>
      </div>
      <div className="flex border-b mb-4">
        <button
          className={`px-4 py-2 ${selectedTab === 'personalInfo' ? 'border-b-2 border-pink-500 text-pink-500' : 'text-gray-500'} focus:outline-none`}
          onClick={() => setSelectedTab('personalInfo')}
        >
          Personal Info
        </button>
        <button
          className={`px-4 py-2 ${selectedTab === 'results' ? 'border-b-2 border-pink-500 text-pink-500' : 'text-gray-500'} focus:outline-none`}
          onClick={() => setSelectedTab('results')}
        >
          Results
        </button>

        <button
          className={`px-4 py-2 ${selectedTab === 'attendance' ? 'border-b-2 border-pink-500 text-pink-500' : 'text-gray-500'} focus:outline-none`}
          onClick={() => setSelectedTab('attendance')}
        >
          Attendance
        </button>
        <button
          className={`px-4 py-2 ${selectedTab === 'settings' ? 'border-b-2 border-pink-500 text-pink-500' : 'text-gray-500'} focus:outline-none`}
          onClick={() => setSelectedTab('settings')}
        >
          Settings
        </button>
      </div>
      <div>
        {selectedTab === 'personalInfo' && renderPersonalInfo()}
        {selectedTab === 'results' && renderResults()}

        {selectedTab === 'attendance' && renderAttendance()}
        {selectedTab === 'settings' && renderSettings()}
      </div>

      {showDeleteModal && renderDeleteModal()}
    </div>
  );
};

export default ViewStudent;
