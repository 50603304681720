import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api'; // Ensure this is correctly defined
import { format } from 'date-fns'; // For formatting dates
import AuthContext from '../context/AuthContext'; // Import AuthContext
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'; // For navigating to the edit page

const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [searchParams, setSearchParams] = useState({
        reg_number: '',
        term: '',
        year: '',
        paynow_reference: ''
    });
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1
    });
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const pageSize = 100;

    const { username } = useContext(AuthContext); // Get the username from AuthContext

    const allowedToEditDelete = username === 'sysadmin';

    useEffect(() => {
        fetchPayments();
    }, [pagination.currentPage]);

    const fetchPayments = async () => {
        setLoading(true);
        setError('');

        try {
            let endpoint = `${BASE_URL}/payments/payments`;
            let params = {
                page: pagination.currentPage,
                pageSize
            };

            const hasSearchParams = Object.values(searchParams).some(param => param !== '');

            if (hasSearchParams) {
                endpoint = `${BASE_URL}/payments/payments/search`;
                Object.entries(searchParams).forEach(([key, value]) => {
                    if (value !== '') {
                        params[key] = value;
                    }
                });
            }

            const response = await axios.get(endpoint, { params });

            setPayments(response.data.payments);
            setPagination({
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages
            });
        } catch (err) {
            console.error('Error fetching payments:', err);
            setError('Failed to fetch payments.');
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prevParams => ({
            ...prevParams,
            [name]: value
        }));
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setPagination(prev => ({
            ...prev,
            currentPage: 1
        }));
        fetchPayments();
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pagination.totalPages) {
            setPagination(prev => ({
                ...prev,
                currentPage: newPage
            }));
        }
    };

    const openModal = (payment) => {
        setSelectedPayment(payment);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedPayment(null);
        setShowModal(false);
    };

    const openDeleteModal = (payment) => {
        setSelectedPayment(payment);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setSelectedPayment(null);
        setShowDeleteModal(false);
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${BASE_URL}/payments/${selectedPayment.id}`);
            toast.success('Payment deleted successfully.');
            fetchPayments(); // Refresh the payments list
            closeDeleteModal();
        } catch (error) {
            toast.error('Failed to delete payment.');
        }
    };

    return (
        <div className="p-4 text-sm">
            <h2 className="text-2xl font-bold mb-4">Payments</h2>

            {/* Search Form */}
            <form onSubmit={handleSearch} className="mb-4 grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                    <label className="block text-gray-700">Registration Number</label>
                    <input
                        type="text"
                        name="reg_number"
                        value={searchParams.reg_number}
                        onChange={handleSearchChange}
                        className="mt-1 p-2 border rounded w-full"
                        placeholder="e.g., R76539A"
                    />
                </div>
                <div>
                    <label className="block text-gray-700">Term</label>
                    <select
                        name="term"
                        value={searchParams.term}
                        onChange={handleSearchChange}
                        className="mt-1 p-2 border rounded w-full"
                    >
                        <option value="">All Terms</option>
                        <option value="1">Term 1</option>
                        <option value="2">Term 2</option>
                        <option value="3">Term 3</option>
                    </select>
                </div>
                <div>
                    <label className="block text-gray-700">Year</label>
                    <input
                        type="number"
                        name="year"
                        value={searchParams.year}
                        onChange={handleSearchChange}
                        className="mt-1 p-2 border rounded w-full"
                        placeholder="e.g., 2024"
                    />
                </div>
                <div>
                    <label className="block text-gray-700">Transaction Reference</label>
                    <input
                        type="text"
                        name="paynow_reference"
                        value={searchParams.paynow_reference}
                        onChange={handleSearchChange}
                        className="mt-1 p-2 border rounded w-full"
                        placeholder="Reference"
                    />
                </div>
                <div className="md:col-span-4">
                    <button
                        type="submit"
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        Search
                    </button>
                    <button
                        type="button"
                        className="mt-4 ml-2 bg-gray-500 text-white px-4 py-2 rounded"
                        onClick={() => {
                            setSearchParams({
                                reg_number: '',
                                term: '',
                                year: '',
                                paynow_reference: ''
                            });
                            setPagination(prev => ({
                                ...prev,
                                currentPage: 1
                            }));
                            fetchPayments();
                        }}
                    >
                        Reset
                    </button>
                </div>
            </form>

            {/* Error Message */}
            {error && (
                <div className="mb-4 text-red-500">
                    {error}
                </div>
            )}

            {/* Loading State */}
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="spinner" style={{ borderColor: '#BB005A transparent transparent transparent' }}></div>
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border">
                        <thead>
                            <tr style={{ backgroundColor: '#BB005A' }}>
                                <th className="px-4 py-2 border text-white">Date</th>
                                <th className="px-4 py-2 border text-white">Reg Number</th>
                                <th className="px-4 py-2 border text-white">Txn Ref</th>
                                <th className="px-4 py-2 border text-white">Received Amount</th>
                                <th className="px-4 py-2 border text-white">Reported Amount</th>
                                <th className="px-4 py-2 border text-white">Currency</th>
                                <th className="px-4 py-2 border text-white">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments.length === 0 ? (
                                <tr>
                                    <td colSpan="7" className="text-center py-4">No payments found.</td>
                                </tr>
                            ) : (
                                payments.map((payment, index) => (
                                    <tr key={payment.id} className={index % 2 === 0 ? 'bg-white' : 'bg-pink-100'}>
                                        <td className="px-4 py-2 border whitespace-nowrap">{format(new Date(payment.created_at), 'yyyy-MM-dd HH:mm')}</td>
                                        <td className="px-4 py-2 border whitespace-nowrap">{payment.reg_number}</td>
                                        <td className="px-4 py-2 border whitespace-nowrap">{payment.paynow_reference}</td>
                                        <td className="px-4 py-2 border whitespace-nowrap">{payment.received_amount}</td>
                                        <td className="px-4 py-2 border whitespace-nowrap">{payment.reported_amount}</td>
                                        <td className="px-4 py-2 border whitespace-nowrap">{payment.currency}</td>
                                        <td className="px-4 py-2 border whitespace-nowrap">
                                            <button
                                                className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                                                onClick={() => openModal(payment)}
                                            >
                                                View 
                                            </button>
                                            {allowedToEditDelete && (
                                                <>
                                                    <Link
                                                        to={`/payments/edit/${payment.id}`}
                                                        className="bg-pink-500 text-white px-2 py-1 rounded mr-2"
                                                    >
                                                        Edit
                                                    </Link>
                                                    <button
                                                        className="bg-red-500 text-white px-2 py-1 rounded"
                                                        onClick={() => openDeleteModal(payment)}
                                                    >
                                                        Delete
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-4">
                <button
                    className={`px-4 py-2 rounded ${pagination.currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                    onClick={() => handlePageChange(pagination.currentPage - 1)}
                    disabled={pagination.currentPage === 1}
                >
                    Previous
                </button>
                <span>
                    Page {pagination.currentPage} of {pagination.totalPages}
                </span>
                <button
                    className={`px-4 py-2 rounded ${pagination.currentPage === pagination.totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                    onClick={() => handlePageChange(pagination.currentPage + 1)}
                    disabled={pagination.currentPage === pagination.totalPages}
                >
                    Next
                </button>
            </div>

            {/* Modal for Payment Details */}
            {showModal && selectedPayment && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
                        <h3 className="text-xl font-bold mb-4">Payment Details</h3>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <strong>Reg Number:</strong> {selectedPayment.reg_number}
                            </div>
                            <div>
                                <strong>Student Name:</strong> {selectedPayment.student_name} {selectedPayment.student_surname}
                            </div>
                            <div>
                                <strong>Form:</strong> {selectedPayment.form}
                            </div>
                            <div>
                                <strong>Class Type:</strong> {selectedPayment.class_type}
                            </div>
                            <div>
                                <strong>Term:</strong> {selectedPayment.term}
                            </div>
                            <div>
                                <strong>Year:</strong> {selectedPayment.year}
                            </div>
                            <div>
                                <strong>Payment Method:</strong> {selectedPayment.payment_method}
                            </div>
                            <div>
                                <strong>Status:</strong> {selectedPayment.status}
                            </div>
                            <div className="col-span-2">
                                <strong>Txn Reference:</strong> {selectedPayment.paynow_reference}
                            </div>
                            <div className="col-span-2">
                                <strong>Created At:</strong> {format(new Date(selectedPayment.created_at), 'yyyy-MM-dd HH:mm')}
                            </div>
                        </div>
                        <div className="mt-4 flex justify-end">
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for Delete Confirmation */}
            {showDeleteModal && selectedPayment && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
                        <h3 className="text-xl font-bold mb-4">Confirm Deletion</h3>
                        <p>Are you sure you want to delete this payment? This action cannot be undone and will affect the student's balance.</p>
                        <div className="mt-4 flex justify-end">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                                onClick={closeDeleteModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={handleDelete}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
};

export default Payments;
