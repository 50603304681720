import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';

const AddSubjectLevelClass = () => {
  const [formData, setFormData] = useState({
    ClassName: '',
    Subject: '',
    Teacher: '',
    TeacherName: '',
    Form: '',
    ClassType: '',
    gradelevelclass: '',
  });
  const [teachers, setTeachers] = useState([]);
  const [gradeLevelClasses, setGradeLevelClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({ isOpen: false, title: '', message: '' });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchTeachers(), fetchGradeLevelClasses()]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchTeachers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/createemployee/teachers/teachers`);
      setTeachers(response.data);
    } catch (error) {
      setModal({
        isOpen: true,
        title: 'Error',
        message: 'Failed to fetch teachers.',
      });
    }
  };

  const fetchGradeLevelClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/getgradelevelclass`);
      setGradeLevelClasses(response.data.classes);
      console.log(response.data.classes)
    } catch (error) {
      setModal({
        isOpen: true,
        title: 'Error',
        message: 'Failed to fetch grade level classes.',
      });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTeacherChange = (e) => {
    const selectedTeacher = teachers.find((teacher) => teacher.EmployeeNumber === e.target.value);
    setFormData({
      ...formData,
      Teacher: selectedTeacher ? selectedTeacher.EmployeeNumber : '',
      TeacherName: selectedTeacher ? `${selectedTeacher.name} ${selectedTeacher.surname}` : '',
    });
  };

  const handleGradeLevelClassChange = (e) => {
    const selectedClassID = e.target.value;
    const selectedGradeLevelClass = gradeLevelClasses.find(
      (gradeLevelClass) => gradeLevelClass.ClassID === parseInt(selectedClassID, 10)
    );

    if (selectedGradeLevelClass) {
      setFormData({
        ...formData,
        gradelevelclass: selectedClassID,
        Form: selectedGradeLevelClass.Form,
        ClassType: selectedGradeLevelClass.ClassType,
      });
    } else {
      setFormData({
        ...formData,
        gradelevelclass: '',
        Form: '',
        ClassType: '',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const subjectClassData = {
        ...formData,
        gradelevelclass: formData.gradelevelclass, // Ensure this is passed correctly
      };

      await axios.post(`${BASE_URL}/subjectlevelclass`, subjectClassData);
      setModal({
        isOpen: true,
        title: 'Success',
        message: 'Subject level class created successfully!',
      });

      // Clear inputs after success
      setFormData({
        ClassName: '',
        Subject: '',
        Teacher: '',
        TeacherName: '',
        Form: '',
        ClassType: '',
        gradelevelclass: '',
      });
    } catch (error) {
      setModal({
        isOpen: true,
        title: 'Error',
        message: 'Error creating subject level class. Either the class exists or there’s a network issue.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setModal({ isOpen: false, title: '', message: '' });
  };

  // Determine the class type options based on the selected Form
  const classTypeOptions =
    formData.Form >= 5
      ? ['Gold', 'Purple', 'Silver','Sciences', 'Arts', 'Commercials']
      : ['Maroon', 'Gray', 'White', 'Red', 'Orange', 'Green', 'Blue'];

  return (
    <div className="p-4 relative">
      <h2 className="text-2xl font-semibold mb-4">Create New Subject Level Class</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block">Grade Level Class</label>
          <select
            name="GradeLevelClassID"
            value={formData.gradelevelclass}
            onChange={handleGradeLevelClassChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          >
            <option value="">Select Grade Level Class</option>
            {Array.isArray(gradeLevelClasses) && gradeLevelClasses.map((gradeLevelClass) => (
              <option key={gradeLevelClass.ClassID} value={gradeLevelClass.ClassID}>
                {gradeLevelClass.ClassName}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Class Name</label>
          <input
            type="text"
            name="ClassName"
            value={formData.ClassName}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          />
        </div>
        <div>
          <label className="block">Form</label>
          <input
            type="text"
            name="Form"
            value={formData.Form}
            className="border rounded px-4 py-2 w-full"
            required
            disabled
          />
        </div>
        <div>
          <label className="block">Class Type</label>
          <select
            name="ClassType"
            value={formData.ClassType}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled
          >
            <option value="">Select Class Type</option>
            {classTypeOptions.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Subject</label>
          <input
            type="text"
            name="Subject"
            value={formData.Subject}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          />
        </div>
        <div>
          <label className="block">Teacher</label>
          <select
            name="Teacher"
            value={formData.Teacher}
            onChange={handleTeacherChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          >
            <option value="">Select Teacher</option>
            {Array.isArray(teachers) && teachers.map((teacher) => (
              <option key={teacher.EmployeeNumber} value={teacher.EmployeeNumber}>
                {`${teacher.name} ${teacher.surname}`}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Term</label>
          <select
            name="Term"
            value={formData.Term}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          >
            <option value="">Select Term</option>
            {[1, 2, 3].map((term) => (
              <option key={term} value={term}>
                {term}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Year</label>
          <select
            name="Year"
            value={formData.Year}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
            disabled={isLoading}
          >
            <option value="">Select Year</option>
            {Array.from({ length: 78 }, (_, i) => 2023 + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          disabled={isLoading}
        >
          {isLoading ? 'Creating...' : 'Create Class'}
        </button>
      </form>

      {/* Modal */}
      {modal.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-md max-w-md mx-auto">
            <h3 className="text-xl font-semibold mb-4">{modal.title}</h3>
            <p>{modal.message}</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={closeModal}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Loader */}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25 z-40">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-16 w-16 border-t-blue-500 animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default AddSubjectLevelClass;
