import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'tailwindcss/tailwind.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ViewEmployee = () => {
  const { EmployeeID } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [payslips, setPayslips] = useState([]);
  const [filteredPayslips, setFilteredPayslips] = useState([]);
  const [searchMonth, setSearchMonth] = useState('');
  const [searchYear, setSearchYear] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState('personalInfo');
  const [selectedPayslip, setSelectedPayslip] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteEmployeeModalOpen, setIsDeleteEmployeeModalOpen] = useState(false);
  const payslipsPerPage = 5;

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/createemployee/${EmployeeID}`);
        setEmployee(response.data);

        const payslipsResponse = await axios.get(`${BASE_URL}/payslips/${response.data.EmployeeNumber}`);
        setPayslips(payslipsResponse.data);
        setFilteredPayslips(payslipsResponse.data);
      } catch (error) {
        console.error('Error fetching employee details or payslips:', error);
      }
    };

    fetchEmployee();
  }, [EmployeeID]);

  const handleSearch = () => {
    const filtered = payslips.filter(payslip =>
      payslip.Month.toLowerCase().includes(searchMonth.toLowerCase()) &&
      payslip.Year.toString().includes(searchYear)
    );
    setFilteredPayslips(filtered);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const openModal = (payslip) => {
    setSelectedPayslip(payslip);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPayslip(null);
  };

  const openDeleteModal = (payslip) => {
    setSelectedPayslip(payslip);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedPayslip(null);
  };

  const openEditModal = (payslip) => {
    setSelectedPayslip(payslip);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedPayslip(null);
  };

  const openDeleteEmployeeModal = () => {
    setIsDeleteEmployeeModalOpen(true);
  };

  const closeDeleteEmployeeModal = () => {
    setIsDeleteEmployeeModalOpen(false);
  };

  const handleRemoveEarning = (index) => {
    setSelectedPayslip(prevState => ({
      ...prevState,
      Earnings: prevState.Earnings.filter((_, i) => i !== index)
    }));
  };

  const handleAddEarning = () => {
    setSelectedPayslip(prevState => ({
      ...prevState,
      Earnings: [...prevState.Earnings, { description: '', amount: 0 }]
    }));
  };

  const handleRemoveDeduction = (index) => {
    setSelectedPayslip(prevState => ({
      ...prevState,
      Deductions: prevState.Deductions.filter((_, i) => i !== index)
    }));
  };

  const handleAddDeduction = () => {
    setSelectedPayslip(prevState => ({
      ...prevState,
      Deductions: [...prevState.Deductions, { description: '', amount: 0 }]
    }));
  };

  const handleEditPayslipChange = (e) => {
    const { name, value } = e.target;
    setSelectedPayslip(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSavePayslip = async () => {
    try {
      await axios.put(`${BASE_URL}/payslips/${selectedPayslip.PayslipID}`, selectedPayslip);
      toast.success('Payslip updated successfully');
      setPayslips(payslips.map(payslip => payslip.PayslipID === selectedPayslip.PayslipID ? selectedPayslip : payslip));
      setFilteredPayslips(filteredPayslips.map(payslip => payslip.PayslipID === selectedPayslip.PayslipID ? selectedPayslip : payslip));
      closeEditModal();
    } catch (error) {
      console.error('Error updating payslip:', error);
      toast.error('Failed to update payslip');
    }
  };

  const handleDeletePayslip = async () => {
    try {
      await axios.delete(`${BASE_URL}/payslips/${selectedPayslip.PayslipID}`);
      toast.success('Payslip deleted successfully');
      setPayslips(payslips.filter(payslip => payslip.PayslipID !== selectedPayslip.PayslipID));
      setFilteredPayslips(filteredPayslips.filter(payslip => payslip.PayslipID !== selectedPayslip.PayslipID));
      closeDeleteModal();
    } catch (error) {
      console.error('Error deleting payslip:', error);
      toast.error('Failed to delete payslip');
    }
  };

  const handleDeleteEmployee = async () => {
    try {
      await axios.put(`${BASE_URL}/employee/employees/${EmployeeID}`);
      toast.success('Employee deleted successfully');
      navigate('/employees');
    } catch (error) {
      console.error('Error deleting employee:', error);
      toast.error('Failed to delete employee');
    }
  };

  const downloadPDF = () => {
    const input = document.getElementById('modal-content');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 10, 10, 180, 160);
      pdf.save('payslip.pdf');
    });
  };

  const indexOfLastPayslip = currentPage * payslipsPerPage;
  const indexOfFirstPayslip = indexOfLastPayslip - payslipsPerPage;
  const currentPayslips = filteredPayslips.slice(indexOfFirstPayslip, indexOfLastPayslip);

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredPayslips.length / payslipsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex justify-center mt-4">
        {pageNumbers.map(number => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`px-3 py-1 mx-1 rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            {number}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="p-4">
      {employee && (
        <>
          <div className="flex justify-between items-center mb-4">
            <div>
              <h3 className="text-xl font-bold">{employee.Name} {employee.Surname}</h3>
              <p className="text-gray-600">{employee.Department} - {employee.Role}</p>
            </div>
            <div className="flex">
              <Link to={`/edit-employee/${employee.EmployeeID}`} className="p-2 bg-blue-500 text-white rounded mr-2">Edit</Link>
              <button
                onClick={openDeleteEmployeeModal}
                className="p-2 bg-red-500 text-white rounded"
              >
                Delete
              </button>
            </div>
          </div>

          <div className="mb-4">
            <button
              onClick={() => setActiveTab('personalInfo')}
              className={`p-2 border-2 ${activeTab === 'personalInfo' ? 'border-[#BB005A] text-[#BB005A]' : 'border-gray-200 text-gray-500'} rounded mr-2`}
            >
              Personal Info
            </button>
            <button
              onClick={() => setActiveTab('payslips')}
              className={`p-2 border-2 ${activeTab === 'payslips' ? 'border-[#BB005A] text-[#BB005A]' : 'border-gray-200 text-gray-500'} rounded`}
            >
              Payslips
            </button>
          </div>


          {activeTab === 'personalInfo' && (
            <div className="bg-white p-4 rounded shadow-md">
              <h3 className="text-xl font-bold mb-4 border-b-2 border-[#BB005A]">Personal Information</h3>
              <div className="grid grid-cols-2 gap-4">
                <p><strong>Name:</strong> {employee.Name}</p>
                <p><strong>Surname:</strong> {employee.Surname}</p>
                <p><strong>Department:</strong> {employee.Department}</p>
                <p><strong>Role:</strong> {employee.Role}</p>
                <p><strong>Date of Birth:</strong> {new Date(employee.DateOfBirth).toISOString().split('T')[0]}</p>
                <p><strong>Address:</strong> {employee.Address}</p>
                <p><strong>Phone Number:</strong> {employee.PhoneNumber}</p>
                <p><strong>National ID Number:</strong> {employee.NationalIDNumber}</p>
                <p><strong>Gender:</strong> {employee.Gender}</p>
                <p><strong>Employee Number:</strong> {employee.EmployeeNumber}</p>
              </div>
              <h3 className="text-xl font-bold mb-4 border-b-2 border-[#BB005A] mt-8">Banking Information</h3>
              <div className="grid grid-cols-2 gap-4">
                {employee.banking && employee.banking.length > 0 ? (
                  employee.banking.map((bankDetail, index) => (
                    <div key={index} className="col-span-2 mb-4">
                      <h4 className="text-lg font-bold mb-2">Bank {index + 1}</h4>
                      <p><strong>Bank Name:</strong> {bankDetail.BankName}</p>
                      <p><strong>Account Number:</strong> {bankDetail.AccountNumber}</p>
                      <p><strong>Currency:</strong> {bankDetail.Currency}</p>
                    </div>
                  ))
                ) : (
                  <p>No banking details available.</p>
                )}
              </div>


            </div>

          )}

          {activeTab === 'payslips' && (
            <div className="bg-white p-4 rounded shadow-md">
              <h3 className="text-xl font-bold mb-4 border-b-2 border-[#BB005A]">Payslips</h3>
              <div className="mb-4 flex">
                <input
                  type="text"
                  placeholder="Search by Month"
                  value={searchMonth}
                  onChange={(e) => setSearchMonth(e.target.value)}
                  className="p-2 border rounded w-full mr-2"
                />
                <input
                  type="text"
                  placeholder="Search by Year"
                  value={searchYear}
                  onChange={(e) => setSearchYear(e.target.value)}
                  className="p-2 border rounded w-full"
                />
                <button
                  onClick={handleSearch}
                  className="p-2 bg-blue-500 text-white rounded ml-2"
                >
                  Search
                </button>
              </div>

              <table className="min-w-full">
                <thead className='bg-[#BB005A] text-white text-start font-bold'>
                  <tr>
                    <th className="py-3 px-4 border">Month</th>
                    <th className="py-3 px-4 border">Year</th>
                    <th className="py-3 px-4 border">Net Salary</th>
                    <th className="py-3 px-4 border">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPayslips.map((payslip) => (
                    <tr key={payslip.PayslipID}>
                      <td className="py-2 px-4 border">{payslip.Month}</td>
                      <td className="py-2 px-4 border">{payslip.Year}</td>
                      <td className="py-2 px-4 border">{parseFloat(payslip.NetSalary).toFixed(2)}</td>
                      <td className="py-2 px-4 border">
                        <button
                          onClick={() => openModal(payslip)}
                          className="p-2 bg-blue-500 text-white rounded mr-2"
                        >
                          View
                        </button>
                        <button
                          onClick={() => openEditModal(payslip)}
                          className="p-2 bg-yellow-500 text-white rounded mr-2"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => openDeleteModal(payslip)}
                          className="p-2 bg-red-500 text-white rounded"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {renderPagination()}
            </div>
          )}

          {isModalOpen && selectedPayslip && (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
              <div className="bg-white p-6 rounded-lg shadow-lg z-10 w-full max-w-lg overflow-y-auto max-h-screen">
                <div id="modal-content">
                  <h3 className="text-2xl font-bold mb-4">Payslip Details</h3>
                  <p><strong>Month:</strong> {selectedPayslip.Month}</p>
                  <p><strong>Year:</strong> {selectedPayslip.Year}</p>
                  <div>
                    <h4 className="text-xl font-bold mt-4 mb-2">Earnings</h4>
                    <ul className="bg-gray-100 p-2 rounded">
                      {selectedPayslip.Earnings && selectedPayslip.Earnings.map((earning, index) => (
                        <li key={index} className="flex justify-between">
                          <span>{earning.description}</span>
                          <span>{earning.amount.toFixed(2)}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="text-xl font-bold mt-4 mb-2">Deductions</h4>
                    <ul className="bg-gray-100 p-2 rounded">
                      {selectedPayslip.Deductions && selectedPayslip.Deductions.map((deduction, index) => (
                        <li key={index} className="flex justify-between">
                          <span>{deduction.description}</span>
                          <span>{deduction.amount.toFixed(2)}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-4">
                    <p className="text-xl font-bold mt-4 mb-2">Net Salary</p>
                    <p className="text-xl font-bold double-underline">{parseFloat(selectedPayslip.NetSalary).toFixed(2)}</p>
                  </div>
                  <button
                    onClick={closeModal}
                    className="p-2 bg-gray-500 text-white rounded mt-4"
                  >
                    Close
                  </button>
                  <button
                    onClick={downloadPDF}
                    className="p-2 bg-green-500 text-white rounded mt-4 ml-4"
                  >
                    Download PDF
                  </button>
                </div>
              </div>
            </div>
          )}

          {isEditModalOpen && selectedPayslip && (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="fixed inset-0 bg-black opacity-50" onClick={closeEditModal}></div>
              <div className="bg-white p-6 rounded-lg shadow-lg z-10 w-full max-w-lg overflow-y-auto max-h-screen">
                <h3 className="text-2xl font-bold mb-4">Edit Payslip</h3>
                <div>
                  <label className="block mb-2">
                    Month:
                    <input
                      type="text"
                      name="Month"
                      value={selectedPayslip.Month}
                      onChange={handleEditPayslipChange}
                      className="p-2 border rounded w-full"
                    />
                  </label>
                  <label className="block mb-2">
                    Year:
                    <input
                      type="text"
                      name="Year"
                      value={selectedPayslip.Year}
                      onChange={handleEditPayslipChange}
                      className="p-2 border rounded w-full"
                    />
                  </label>
                  <div>
                    <h4 className="text-xl font-bold mt-4 mb-2">Earnings</h4>
                    {selectedPayslip.Earnings && selectedPayslip.Earnings.map((earning, index) => (
                      <div key={index} className="flex justify-between items-center mb-2">
                        <input
                          type="text"
                          name={`Earnings-${index}-description`}
                          value={earning.description}
                          onChange={(e) => {
                            const newEarnings = [...selectedPayslip.Earnings];
                            newEarnings[index].description = e.target.value;
                            setSelectedPayslip({ ...selectedPayslip, Earnings: newEarnings });
                          }}
                          className="p-2 border rounded w-full mr-2"
                        />
                        <input
                          type="number"
                          name={`Earnings-${index}-amount`}
                          value={earning.amount}
                          onChange={(e) => {
                            const newEarnings = [...selectedPayslip.Earnings];
                            newEarnings[index].amount = parseFloat(e.target.value) || 0;
                            setSelectedPayslip({ ...selectedPayslip, Earnings: newEarnings });
                          }}
                          className="p-2 border rounded w-full"
                        />
                        <button
                          onClick={() => handleRemoveEarning(index)}
                          className="p-2 bg-red-500 text-white rounded ml-2"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={handleAddEarning}
                      className="p-2 bg-green-500 text-white rounded mt-2"
                    >
                      Add Earning
                    </button>
                  </div>
                  <div>
                    <h4 className="text-xl font-bold mt-4 mb-2">Deductions</h4>
                    {selectedPayslip.Deductions && selectedPayslip.Deductions.map((deduction, index) => (
                      <div key={index} className="flex justify-between items-center mb-2">
                        <input
                          type="text"
                          name={`Deductions-${index}-description`}
                          value={deduction.description}
                          onChange={(e) => {
                            const newDeductions = [...selectedPayslip.Deductions];
                            newDeductions[index].description = e.target.value;
                            setSelectedPayslip({ ...selectedPayslip, Deductions: newDeductions });
                          }}
                          className="p-2 border rounded w-full mr-2"
                        />
                        <input
                          type="number"
                          name={`Deductions-${index}-amount`}
                          value={deduction.amount}
                          onChange={(e) => {
                            const newDeductions = [...selectedPayslip.Deductions];
                            newDeductions[index].amount = parseFloat(e.target.value) || 0;
                            setSelectedPayslip({ ...selectedPayslip, Deductions: newDeductions });
                          }}
                          className="p-2 border rounded w-full"
                        />
                        <button
                          onClick={() => handleRemoveDeduction(index)}
                          className="p-2 bg-red-500 text-white rounded ml-2"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={handleAddDeduction}
                      className="p-2 bg-green-500 text-white rounded mt-2"
                    >
                      Add Deduction
                    </button>
                  </div>
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={closeEditModal}
                      className="p-2 bg-gray-500 text-white rounded mr-2"
                    >
                      Close
                    </button>
                    <button
                      onClick={handleSavePayslip}
                      className="p-2 bg-green-500 text-white rounded"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isDeleteModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="fixed inset-0 bg-black opacity-50" onClick={closeDeleteModal}></div>
              <div className="bg-white p-6 rounded-lg shadow-lg z-10 w-full max-w-md">
                <h3 className="text-2xl font-bold mb-4">Confirm Deletion</h3>
                <p>Are you sure you want to delete this payslip?</p>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={closeDeleteModal}
                    className="p-2 bg-gray-500 text-white rounded mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleDeletePayslip}
                    className="p-2 bg-red-500 text-white rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}

          {isDeleteEmployeeModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="fixed inset-0 bg-black opacity-50" onClick={closeDeleteEmployeeModal}></div>
              <div className="bg-white p-6 rounded-lg shadow-lg z-10 w-full max-w-md">
                <h3 className="text-2xl font-bold mb-4">Confirm Deletion</h3>
                <p>Hey, you are about to delete this employee. Are you sure?</p>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={closeDeleteEmployeeModal}
                    className="p-2 bg-gray-500 text-white rounded mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleDeleteEmployee}
                    className="p-2 bg-red-500 text-white rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default ViewEmployee;
