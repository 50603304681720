// src/components/FinancialsTable.js
import React from 'react';

const FinancialsTable = () => {
  return (
    <div className="p-4 overflow-x-auto">
      <table className="min-w-full border">
        <thead>
          <tr className="bg-pink-600 text-white">
            <th className="border px-4 py-2">Student No</th>
            <th className="border px-4 py-2">Reference No</th>
            <th className="border px-4 py-2">Class</th>
            <th className="border px-4 py-2">Form</th>
            <th className="border px-4 py-2">Term</th>
            <th className="border px-4 py-2">Year</th>
            <th className="border px-4 py-2">Currency</th>
            <th className="border px-4 py-2">Rpt Currency</th>
            <th className="border px-4 py-2">Amount</th>
            <th className="border px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(8)].map((_, index) => (
            <tr key={index} className="bg-pink-100">
              <td className="border px-4 py-2">R206538H</td>
              <td className="border px-4 py-2">237DH8WEGWD</td>
              <td className="border px-4 py-2">Form 1 Maroon</td>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">2</td>
              <td className="border px-4 py-2">2024</td>
              <td className="border px-4 py-2">ZiG</td>
              <td className="border px-4 py-2">USD</td>
              <td className="border px-4 py-2">100</td>
              <td className="border px-4 py-2">
                <button className="bg-black text-white px-4 py-2 rounded">View</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FinancialsTable;
