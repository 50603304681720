import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from '../context/api';

const MakePayment = () => {
    const [formData, setFormData] = useState({
        reg_number: '',
        class_type: '',
        form: '',
        year: '',
        term: '',
        amount_paid: '',
        currency: 'USD', // Default currency
        payment_reference: ''
    });

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const generateReference = () => {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let reference = '';
        for (let i = 0; i < 14; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            reference += charset.charAt(randomIndex);
        }
        setFormData({ ...formData, payment_reference: reference });
    };

    const getClassTypeOptions = () => {
        if (formData.form >= 1 && formData.form <= 4) {
            return ['Maroon', 'Gray', 'White', 'Red', 'Orange', 'Green', 'Blue'];
        } else if (formData.form >= 5 && formData.form <= 6) {
            return ['Gold', 'Purple', 'Silver'];
        }
        return [];
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/payments/cash`, formData);
            setShowSuccessModal(true);
            setFormData({
                reg_number: '',
                class_type: '',
                form: '',
                year: '',
                term: '',
                amount_paid: '',
                currency: 'USD',
                payment_reference: ''
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to process payment.';
            setErrorMessages([errorMessage]);
            setShowErrorModal(true);
        }
    };

    return (
        <div className="w-full p-4 bg-white">
            <h2 className="text-xl font-bold mb-4">Make Payment</h2>
            <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                <div className="mb-4">
                    <label className="block text-gray-700">Registration Number</label>
                    <input
                        type="text"
                        name="reg_number"
                        value={formData.reg_number}
                        onChange={handleInputChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Form</label>
                    <select
                        name="form"
                        value={formData.form}
                        onChange={handleInputChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    >
                        <option value="">Select Form</option>
                        {[1, 2, 3, 4, 5, 6].map(form => (
                            <option key={form} value={form}>{form}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Class Type</label>
                    <select
                        name="class_type"
                        value={formData.class_type}
                        onChange={handleInputChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    >
                        <option value="">Select Class Type</option>
                        {getClassTypeOptions().map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Year</label>
                    <select
                        name="year"
                        value={formData.year}
                        onChange={handleInputChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    >
                        <option value="">Select Year</option>
                        {Array.from({ length: 80 }, (_, i) => 2024 + i).map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Term</label>
                    <select
                        name="term"
                        value={formData.term}
                        onChange={handleInputChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    >
                        <option value="">Select Term</option>
                        {[1, 2, 3].map(term => (
                            <option key={term} value={term}>{term}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Amount Paid</label>
                    <input
                        type="number"
                        name="amount_paid"
                        value={formData.amount_paid}
                        onChange={handleInputChange}
                        className="mt-1 p-2 border rounded w-full"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Currency</label>
                    <select
                        name="currency"
                        value={formData.currency}
                        onChange={handleInputChange}
                        className="mt-1 p-2 border rounded w-full"
                    >
                        <option value="USD">USD</option>
                        <option value="ZIG">ZIG</option>
                    </select>
                </div>
                <div className="mb-4 flex items-end">
                    <div className="flex-1">
                        <label className="block text-gray-700">Payment Reference (Optional)</label>
                        <input
                            type="text"
                            name="payment_reference"
                            value={formData.payment_reference}
                            onChange={handleInputChange}
                            className="mt-1 p-2 border rounded w-full"
                        />
                    </div>
                    <button
                        type="button"
                        onClick={generateReference}
                        className="ml-2 bg-[#BB005A] text-white px-4 py-2 rounded"
                    >
                        Generate
                    </button>
                </div>
                <div className="col-span-2">
                    <button type="submit" className="mt-4 bg-[#BB005A] text-white w-full p-2 rounded">Submit Payment</button>
                </div>
            </form>

            {/* Error Modal */}
            {showErrorModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded max-w-md w-full">
                        <h3 className="text-lg font-bold text-red-500">Error</h3>
                        <ul className="list-disc list-inside text-red-500 mt-2">
                            {errorMessages.map((msg, index) => (
                                <li key={index}>{msg}</li>
                            ))}
                        </ul>
                        <button
                            onClick={() => setShowErrorModal(false)}
                            className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-green-100 p-6 rounded max-w-md w-full">
                        <h3 className="text-lg font-bold text-green-700">Payment Successful!</h3>
                        <p className="text-green-700 mt-2">Your payment has been successfully processed.</p>
                        <button
                            onClick={() => setShowSuccessModal(false)}
                            className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MakePayment;
