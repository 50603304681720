import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import 'tailwindcss/tailwind.css';

const CreatePayslip = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [earnings, setEarnings] = useState([{ description: '', amount: '' }]);
  const [deductions, setDeductions] = useState([{ description: '', amount: '' }]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/createemployee`);
        setEmployees(response.data.employees);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };
    fetchEmployees();
  }, []);

  const handleEarningsChange = (index, field, value) => {
    const newEarnings = [...earnings];
    newEarnings[index][field] = value;
    setEarnings(newEarnings);
  };

  const handleDeductionsChange = (index, field, value) => {
    const newDeductions = [...deductions];
    newDeductions[index][field] = value;
    setDeductions(newDeductions);
  };

  const handleAddEarning = () => {
    setEarnings([...earnings, { description: '', amount: '' }]);
  };

  const handleAddDeduction = () => {
    setDeductions([...deductions, { description: '', amount: '' }]);
  };

  const handleRemoveEarning = (index) => {
    const newEarnings = earnings.filter((_, i) => i !== index);
    setEarnings(newEarnings);
  };

  const handleRemoveDeduction = (index) => {
    const newDeductions = deductions.filter((_, i) => i !== index);
    setDeductions(newDeductions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert amounts to integers
    const formattedEarnings = earnings.map((earning) => ({
      ...earning,
      amount: parseInt(earning.amount, 10),
    }));

    const formattedDeductions = deductions.map((deduction) => ({
      ...deduction,
      amount: parseInt(deduction.amount, 10),
    }));

    const netSalary = formattedEarnings.reduce((sum, e) => sum + e.amount, 0) - formattedDeductions.reduce((sum, d) => sum + d.amount, 0);

    const payslipData = {
      employeeNumber: selectedEmployee,
      month,
      year,
      earnings: formattedEarnings,
      deductions: formattedDeductions,
      netSalary,
    };

    try {
      await axios.post(`${BASE_URL}/payslips`, payslipData);
      toast.success('Payslip created successfully!');
      
    } catch (error) {
      console.error('Error creating payslip:', error);
      toast.error('Failed to create payslip');
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Create Payslip</h2>
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md">
        <div className="mb-4">
          <label className="block text-gray-700">Employee:</label>
          <select value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)} className="border rounded px-4 py-2 w-full">
            <option value="">Select Employee</option>
            {employees.map((employee) => (
              <option key={employee.EmployeeID} value={employee.EmployeeNumber}>
                {employee.Name} {employee.Surname}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Month:</label>
          <select value={month} onChange={(e) => setMonth(e.target.value)} className="border rounded px-4 py-2 w-full">
            <option value="">Select Month</option>
            {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month) => (
              <option key={month} value={month}>{month}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Year:</label>
          <select value={year} onChange={(e) => setYear(e.target.value)} className="border rounded px-4 py-2 w-full">
            <option value="">Select Year</option>
            {[...Array(77)].map((_, i) => (
              <option key={i} value={2024 + i}>{2024 + i}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Earnings:</label>
          {earnings.map((earning, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                placeholder="Description"
                value={earning.description}
                onChange={(e) => handleEarningsChange(index, 'description', e.target.value)}
                className="border rounded px-4 py-2 mr-2 w-full"
              />
              <input
                type="number"
                placeholder="Amount"
                value={earning.amount}
                onChange={(e) => handleEarningsChange(index, 'amount', e.target.value)}
                className="border rounded px-4 py-2 mr-2 w-full"
              />
              <button type="button" onClick={() => handleRemoveEarning(index)} className="text-red-500">Remove</button>
            </div>
          ))}
          <button type="button" onClick={handleAddEarning} className="text-blue-500">Add Earning</button>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Deductions:</label>
          {deductions.map((deduction, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                placeholder="Description"
                value={deduction.description}
                onChange={(e) => handleDeductionsChange(index, 'description', e.target.value)}
                className="border rounded px-4 py-2 mr-2 w-full"
              />
              <input
                type="number"
                placeholder="Amount"
                value={deduction.amount}
                onChange={(e) => handleDeductionsChange(index, 'amount', e.target.value)}
                className="border rounded px-4 py-2 mr-2 w-full"
              />
              <button type="button" onClick={() => handleRemoveDeduction(index)} className="text-red-500">Remove</button>
            </div>
          ))}
          <button type="button" onClick={handleAddDeduction} className="text-blue-500">Add Deduction</button>
        </div>
        <button type="submit" className="p-2 bg-green-500 text-white rounded">Create Payslip</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CreatePayslip;
