import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditInvoice = () => {
  const { id: invoiceId } = useParams();
  const [invoice, setInvoice] = useState({
    classType: '',
    form: '',
    term: '',
    year: '',
    rtgsPercentage: '',
    items: [{ name: '', amount: '' }],
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/invoices/${invoiceId}`);
        const invoiceData = response.data;

        // Mapping the data to the state
        setInvoice({
          classType: invoiceData.class_type,
          form: invoiceData.form,
          term: invoiceData.term,
          year: invoiceData.year,
          rtgsPercentage: invoiceData.rtgs_percentage,
          items: invoiceData.items.map(item => ({ name: item.item_name, amount: item.amount })),
        });
      } catch (error) {
        console.error('Failed to fetch invoice', error);
        toast.error('Failed to fetch invoice.');
      }
    };

    fetchInvoice();
  }, [invoiceId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoice({ ...invoice, [name]: value });
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const items = [...invoice.items];
    items[index][name] = value;
    setInvoice({ ...invoice, items });
  };

  const addItem = () => {
    setInvoice({ ...invoice, items: [...invoice.items, { name: '', amount: '' }] });
  };

  const removeItem = (index) => {
    const items = [...invoice.items];
    items.splice(index, 1);
    setInvoice({ ...invoice, items });
  };

  const calculateTotalAmount = () => {
    return invoice.items.reduce((total, item) => total + parseFloat(item.amount || 0), 0);
  };

  const validateForm = () => {
    const { classType, form, term, year, rtgsPercentage, items } = invoice;
    if (!classType || !form || !term || !year || !rtgsPercentage) {
      toast.error('Please fill in all required fields.');
      return false;
    }

    for (let i = 0; i < items.length; i++) {
      if (!items[i].name || !items[i].amount) {
        toast.error('Please fill in all item fields.');
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const newTotalAmount = calculateTotalAmount();

    try {
      await axios.put(`${BASE_URL}/invoices/${invoiceId}`, {
        invoiceId, // Pass invoiceId
        newTotalAmount, // Pass the calculated total amount
        class_type: invoice.classType, // Include classType as class_type
        form: invoice.form,
        term: invoice.term,
        year: invoice.year,
        rtgs_percentage: invoice.rtgsPercentage,
        items: invoice.items.map(item => ({ item_name: item.name, amount: item.amount })),
      });
      setShowSuccessModal(true);
      setShowConfirmModal(false); // Close confirmation modal on success
    } catch (error) {
      console.error('Failed to update invoice', error);
      toast.error('Failed to update invoice.');
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setShowConfirmModal(true); // Show the confirmation modal
  };

  return (
    <div className="max-w-lg mx-auto p-4 bg-white rounded shadow-md">
      <h2 className="text-xl font-bold mb-4">Edit Invoice</h2>
      <form onSubmit={handleFormSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Form</label>
          <input
            type="text"
            name="form"
            value={invoice.form}
            className="mt-1 p-2 border rounded w-full bg-gray-100"
            readOnly
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Class Type</label>
          <input
            type="text"
            name="classType"
            value={invoice.classType}
            className="mt-1 p-2 border rounded w-full bg-gray-100"
            readOnly
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Term</label>
          <input
            type="text"
            name="term"
            value={invoice.term}
            className="mt-1 p-2 border rounded w-full bg-gray-100"
            readOnly
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Year</label>
          <input
            type="text"
            name="year"
            value={invoice.year}
            onChange={handleInputChange}
            className="mt-1 p-2 border rounded w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">RTGS Percentage</label>
          <input
            type="text"
            name="rtgsPercentage"
            value={invoice.rtgsPercentage}
            onChange={handleInputChange}
            className="mt-1 p-2 border rounded w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Invoice Items</label>
          {invoice.items.map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                name="name"
                placeholder="Item Name"
                value={item.name}
                onChange={(e) => handleItemChange(index, e)}
                className="mr-2 p-2 border rounded w-1/2"
                required
              />
              <input
                type="number"
                name="amount"
                placeholder="Amount"
                value={item.amount}
                onChange={(e) => handleItemChange(index, e)}
                className="mr-2 p-2 border rounded w-1/3"
                required
              />
              <button type="button" onClick={() => removeItem(index)} className="text-red-500">Remove</button>
            </div>
          ))}
          <button type="button" onClick={addItem} className="text-blue-500">Add Item</button>
        </div>
        <button type="submit" className="mt-4 bg-blue-500 text-white p-2 rounded">Update Invoice</button>
      </form>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-md max-w-md mx-auto">
            <h3 className="text-xl font-semibold mb-4">Success</h3>
            <p>Invoice updated successfully!</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowSuccessModal(false)}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      {showConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-md max-w-md mx-auto">
            <h3 className="text-xl font-semibold mb-4">Confirmation</h3>
            <p>This will impact students who have already paid towards this invoice. Make sure you double check before proceeding.</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Yes, Proceed
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default EditInvoice;
