import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../context/api';

const departments = ['Teaching', 'Human Resources', 'Finance', 'Administration'];
const roles = ['Teacher', 'Service Worker', 'Bursar', 'Headmaster', 'Groundsman', 'Other'];

const EditEmployee = () => {
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({
    Department: '',
    Role: '',
    Name: '',
    Surname: '',
    DateOfBirth: '',
    Address: '',
    PhoneNumber: '',
    NationalIDNumber: '',
    Gender: '',
    EmployeeNumber: '',
    Password: '',
    banking: [{ BankName: '', AccountNumber: '', Currency: '' }] // Initial state as array to hold banking info
  });

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/createemployee/${employeeId}`);
        const data = {
          ...response.data,
          DateOfBirth: new Date(response.data.DateOfBirth).toISOString().split('T')[0],
          banking: response.data.banking || [{ BankName: '', AccountNumber: '', Currency: '' }] // Ensure banking is an array
        };
        setEmployee(data); // Set employee data including banking info
      } catch (error) {
        console.error('Error fetching employee:', error);
      }
    };
    fetchEmployee();
  }, [employeeId]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const banking = [...employee.banking];
    banking[index][name] = value;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      banking
    }));
  };

  const handleAddBankingDetail = () => {
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      banking: [...prevEmployee.banking, { BankName: '', AccountNumber: '', Currency: '' }]
    }));
  };

  const handleRemoveBankingDetail = (index) => {
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      banking: prevEmployee.banking.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      employeeData: {
        Department: employee.Department,
        Role: employee.Role,
        Name: employee.Name,
        Surname: employee.Surname,
        DateOfBirth: employee.DateOfBirth,
        Address: employee.Address,
        PhoneNumber: employee.PhoneNumber,
        NationalIDNumber: employee.NationalIDNumber,
        Gender: employee.Gender,
        EmployeeNumber: employee.EmployeeNumber,
        Password: employee.Password
      },
      bankDetails: employee.banking // Send the banking array
    };

    console.log('Payload:', payload);
    try {
      const response = await axios.put(`${BASE_URL}/createemployee/${employeeId}`, payload);
      if (response.status === 200) {
        toast.success('Employee updated successfully');
        setTimeout(() => {
          navigate('/employees');
        }, 2000);
      }
    } catch (error) {
      console.error('Error updating employee:', error);
      toast.error('Error updating employee');
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Edit Employee</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block">Department</label>
          <select
            name="Department"
            value={employee.Department}
            onChange={(e) => setEmployee({ ...employee, Department: e.target.value })}
            className="p-2 border rounded w-full"
            required
          >
            <option value="" disabled>Select Department</option>
            {departments.map((dept) => (
              <option key={dept} value={dept}>{dept}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Role</label>
          <select
            name="Role"
            value={employee.Role}
            onChange={(e) => setEmployee({ ...employee, Role: e.target.value })}
            className="p-2 border rounded w-full"
            required
          >
            <option value="" disabled>Select Role</option>
            {roles.map((role) => (
              <option key={role} value={role}>{role}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Name</label>
          <input
            type="text"
            name="Name"
            value={employee.Name}
            onChange={(e) => setEmployee({ ...employee, Name: e.target.value })}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Surname</label>
          <input
            type="text"
            name="Surname"
            value={employee.Surname}
            onChange={(e) => setEmployee({ ...employee, Surname: e.target.value })}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Date of Birth</label>
          <input
            type="date"
            name="DateOfBirth"
            value={employee.DateOfBirth}
            onChange={(e) => setEmployee({ ...employee, DateOfBirth: e.target.value })}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Address</label>
          <input
            type="text"
            name="Address"
            value={employee.Address}
            onChange={(e) => setEmployee({ ...employee, Address: e.target.value })}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Phone Number</label>
          <input
            type="text"
            name="PhoneNumber"
            value={employee.PhoneNumber}
            onChange={(e) => setEmployee({ ...employee, PhoneNumber: e.target.value })}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">National ID Number</label>
          <input
            type="text"
            name="NationalIDNumber"
            value={employee.NationalIDNumber}
            onChange={(e) => setEmployee({ ...employee, NationalIDNumber: e.target.value })}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Gender</label>
          <select
            name="Gender"
            value={employee.Gender}
            onChange={(e) => setEmployee({ ...employee, Gender: e.target.value })}
            className="p-2 border rounded w-full"
            required
          >
            <option value="" disabled>Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div>
          <label className="block">Employee Number</label>
          <input
            type="text"
            name="EmployeeNumber"
            value={employee.EmployeeNumber}
            className="p-2 border rounded w-full"
            readOnly
          />
        </div>
        <div>
          <label className="block">Password</label>
          <input
            type="text"
            name="Password"
            value={employee.Password}
            onChange={(e) => setEmployee({ ...employee, Password: e.target.value })}
            className="p-2 border rounded w-full"
            required
          />
        </div>

        {/* Banking Information */}
        <div>
          <label className="block">Banking Information:</label>
          {employee.banking.map((bankDetail, index) => (
            <div key={index} className="mb-3">
              <input
                type="text"
                name="BankName"
                value={bankDetail.BankName || ''}
                onChange={(e) => handleChange(e, index)}
                placeholder="Bank Name"
                className="mr-2 p-2 border rounded"
                required
              />
              <input
                type="text"
                name="AccountNumber"
                value={bankDetail.AccountNumber || ''}
                onChange={(e) => handleChange(e, index)}
                placeholder="Account Number"
                className="mr-2 p-2 border rounded"
                required
              />
              <input
                type="text"
                name="Currency"
                value={bankDetail.Currency || ''}
                onChange={(e) => handleChange(e, index)}
                placeholder="Currency"
                className="mr-2 p-2 border rounded"
                required
              />
              {index > 0 && (
                <button type="button" onClick={() => handleRemoveBankingDetail(index)} className="bg-red-500 text-white p-2 rounded">
                  Remove
                </button>
              )}
            </div>
          ))}
          <button type="button" onClick={handleAddBankingDetail} className="bg-green-500 text-white p-2 rounded">
            Add Banking Detail
          </button>
        </div>

        <button
          type="submit"
          className="p-2 bg-blue-500 text-white rounded"
        >
          Update Employee
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditEmployee;
