import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../context/api';

const EditStudent = () => {
  const { regnumber } = useParams();
  const navigate = useNavigate();

  const [studentData, setStudentData] = useState({
    Name: '',
    Surname: '',
    DateOfBirth: '',
    NationalIDNumber: '',
    Address: '',
    Gender: '',
    HasMedicalAid: 0,
  });

  const [guardians, setGuardians] = useState([
    { Name: '', Surname: '', NationalIDNumber: '', Address: '', PhoneNumber: '', Gender: '' }
  ]);

  useEffect(() => {
    fetchStudentData();
  }, [regnumber]);

  const fetchStudentData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/getstudent/${regnumber}`);
      const { student, guardians } = response.data;
      setStudentData({
        ...student,
        DateOfBirth: new Date(student.DateOfBirth).toISOString().split('T')[0] // Format date correctly
      });
      const formattedGuardians = guardians.map(guardian => ({
        ...guardian,
        DateOfBirth: new Date(guardian.DateOfBirth).toISOString().split('T')[0] // Format date correctly
      }));
      setGuardians(formattedGuardians);
    } catch (error) {
      console.error('Error fetching student data:', error);
    }
  };

  const handleInputChange = (e, setState, state) => {
    const { name, value } = e.target;
    if (name === 'HasMedicalAid') {
      setState({ ...state, [name]: value === 'true' ? 1 : 0 });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleGuardianChange = (index, e) => {
    const { name, value } = e.target;
    const newGuardians = [...guardians];
    newGuardians[index][name] = value;
    setGuardians(newGuardians);
  };

  const addGuardian = () => {
    setGuardians([...guardians, { Name: '', Surname: '', NationalIDNumber: '', Address: '', PhoneNumber: '', Gender: '' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${BASE_URL}/createstudent/students/${regnumber}`, {
        studentData,
        guardians,
      });
      alert('Student updated successfully');
      navigate(`/view-student/${regnumber}`);
    } catch (error) {
      console.error('Error updating student:', error);
    }
  };

  return (
    <div className="p-4 bg-white rounded shadow-md max-w-2xl mx-auto">
      <h2 className="text-xl font-semibold mb-4">Edit Student</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="Name">First Name</label>
          <input
            type="text"
            id="Name"
            name="Name"
            className="w-full px-3 py-2 border rounded-lg"
            value={studentData.Name}
            onChange={(e) => handleInputChange(e, setStudentData, studentData)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="Surname">Surname</label>
          <input
            type="text"
            id="Surname"
            name="Surname"
            className="w-full px-3 py-2 border rounded-lg"
            value={studentData.Surname}
            onChange={(e) => handleInputChange(e, setStudentData, studentData)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="DateOfBirth">Date of Birth</label>
          <input
            type="date"
            id="DateOfBirth"
            name="DateOfBirth"
            className="w-full px-3 py-2 border rounded-lg"
            value={studentData.DateOfBirth}
            onChange={(e) => handleInputChange(e, setStudentData, studentData)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="NationalIDNumber">National ID Number</label>
          <input
            type="text"
            id="NationalIDNumber"
            name="NationalIDNumber"
            className="w-full px-3 py-2 border rounded-lg"
            value={studentData.NationalIDNumber}
            onChange={(e) => handleInputChange(e, setStudentData, studentData)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="Address">Address</label>
          <input
            type="text"
            id="Address"
            name="Address"
            className="w-full px-3 py-2 border rounded-lg"
            value={studentData.Address}
            onChange={(e) => handleInputChange(e, setStudentData, studentData)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="Gender">Gender</label>
          <select
            id="Gender"
            name="Gender"
            className="w-full px-3 py-2 border rounded-lg"
            value={studentData.Gender}
            onChange={(e) => handleInputChange(e, setStudentData, studentData)}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="HasMedicalAid">Has Medical Aid</label>
          <select
            id="HasMedicalAid"
            name="HasMedicalAid"
            className="w-full px-3 py-2 border rounded-lg"
            value={studentData.HasMedicalAid ? 'true' : 'false'}
            onChange={(e) => handleInputChange(e, setStudentData, studentData)}
            required
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>

        <h3 className="text-lg font-semibold mb-4">Guardians</h3>
        {guardians.map((guardian, index) => (
          <div key={index} className="mb-4 border p-4 rounded">
            <div className="mb-2">
              <label className="block text-gray-700 mb-2" htmlFor={`guardianName${index}`}>First Name</label>
              <input
                type="text"
                id={`guardianName${index}`}
                name="Name"
                className="w-full px-3 py-2 border rounded-lg"
                value={guardian.Name}
                onChange={(e) => handleGuardianChange(index, e)}
                required
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 mb-2" htmlFor={`guardianSurname${index}`}>Surname</label>
              <input
                type="text"
                id={`guardianSurname${index}`}
                name="Surname"
                className="w-full px-3 py-2 border rounded-lg"
                value={guardian.Surname}
                onChange={(e) => handleGuardianChange(index, e)}
                required
              />
            </div>
          
            <div className="mb-2">
              <label className="block text-gray-700 mb-2" htmlFor={`guardianNationalIDNumber${index}`}>National ID Number</label>
              <input
                type="text"
                id={`guardianNationalIDNumber${index}`}
                name="NationalIDNumber"
                className="w-full px-3 py-2 border rounded-lg"
                value={guardian.NationalIDNumber}
                onChange={(e) => handleGuardianChange(index, e)}
                required
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 mb-2" htmlFor={`guardianAddress${index}`}>Address</label>
              <input
                type="text"
                id={`guardianAddress${index}`}
                name="Address"
                className="w-full px-3 py-2 border rounded-lg"
                value={guardian.Address}
                onChange={(e) => handleGuardianChange(index, e)}
                required
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 mb-2" htmlFor={`guardianPhoneNumber${index}`}>Phone Number</label>
              <input
                type="text"
                id={`guardianPhoneNumber${index}`}
                name="PhoneNumber"
                className="w-full px-3 py-2 border rounded-lg"
                value={guardian.PhoneNumber}
                onChange={(e) => handleGuardianChange(index, e)}
                required
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 mb-2" htmlFor={`guardianGender${index}`}>Gender</label>
              <select
                id={`guardianGender${index}`}
                name="Gender"
                className="w-full px-3 py-2 border rounded-lg"
                value={guardian.Gender}
                onChange={(e) => handleGuardianChange(index, e)}
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>
        ))}
        <button
          type="button"
          className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 transition duration-300 mb-4"
          onClick={addGuardian}
        >
          Add Guardian
        </button>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditStudent;
