// src/components/TopBar.js
import React from 'react';

const TopBar = () => {
  return (
    <div className="bg-white p-4 shadow-md flex justify-between">
      <div className="flex space-x-4">
        <input type="text" placeholder="Enter student reg number..." className="border p-2 rounded" />
        <input type="text" placeholder="Enter reference number..." className="border p-2 rounded" />
        <button className="bg-pink-500 text-white px-4 py-2 rounded">Search</button>
        <button className="bg-green-500 text-white px-4 py-2 rounded">Enter new record</button>
      </div>
      <div className="flex space-x-4">
        <select className="border p-2 rounded">
          <option>Filter by class</option>
        </select>
        <select className="border p-2 rounded">
          <option>Finances</option>
        </select>
        <select className="border p-2 rounded">
          <option>Form</option>
        </select>
        <button className="bg-red-500 text-white px-4 py-2 rounded">Remove filter</button>
        <button className="bg-blue-500 text-white px-4 py-2 rounded">Apply filter</button>
      </div>
    </div>
  );
};

export default TopBar;
