import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditPayment = () => {
    const { id } = useParams(); // Assuming you pass the payment ID in the URL
    const navigate = useNavigate(); // useNavigate instead of useHistory
    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(null);
    const [newRegNumber, setNewRegNumber] = useState('');
    const [newAmount, setNewAmount] = useState('');
    const [newCurrency, setNewCurrency] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showFinalModal, setShowFinalModal] = useState(false);

    useEffect(() => {
        fetchPaymentDetails();
    }, []);

    const fetchPaymentDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/temp-payments/payments/${id}`);
            setPayment(response.data);
            setNewRegNumber(response.data.reg_number);
            setNewAmount(response.data.received_amount);
            setNewCurrency(response.data.currency);
        } catch (error) {
            console.error('Error fetching payment details:', error);
            toast.error('Failed to fetch payment details.');
        } finally {
            setLoading(false);
        }
    };

    const handleCorrectRegNumber = async () => {
        setShowConfirmationModal(true);
    };

    const handleEditAmount = async () => {
        setShowConfirmationModal(true);
    };

    const confirmUpdate = async () => {
        setLoading(true);
        setShowConfirmationModal(false);
        try {
            if (newRegNumber !== payment.reg_number) {
                await axios.put(`${BASE_URL}/edit-payments/payments/correct-reg-number`, {
                    paymentId: id,
                    correctRegNumber: newRegNumber
                });
                toast.success('Registration number updated successfully.');
            }
            if (newAmount !== payment.received_amount || newCurrency !== payment.currency) {
                await axios.put(`${BASE_URL}/edit-payments/payments/edit-amount`, {
                    paymentId: id,
                    newAmount,
                    newCurrency
                });
                toast.success('Payment amount updated successfully.');
            }
            setShowFinalModal(true);
        } catch (error) {
            console.error('Error updating payment details:', error);
            toast.error('Failed to update payment details.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4 w-full max-w-4xl mx-auto bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6">Edit Payment</h2>

            {loading ? (
                <div className="text-center text-gray-600">Loading...</div>
            ) : payment ? (
                <div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Current Registration Number</label>
                        <input
                            type="text"
                            value={newRegNumber}
                            onChange={(e) => setNewRegNumber(e.target.value)}
                            className="mt-1 p-3 border rounded w-full"
                        />
                        <button
                            className="mt-4 bg-blue-600 text-white px-5 py-2 rounded"
                            onClick={handleCorrectRegNumber}
                            disabled={loading}
                        >
                            Update Registration Number
                        </button>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Current Amount</label>
                        <input
                            type="number"
                            value={newAmount}
                            onChange={(e) => setNewAmount(e.target.value)}
                            className="mt-1 p-3 border rounded w-full"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Currency</label>
                        <select
                            value={newCurrency}
                            onChange={(e) => setNewCurrency(e.target.value)}
                            className="mt-1 p-3 border rounded w-full"
                        >
                            <option value="USD">USD</option>
                            <option value="ZIG">ZIG</option>
                        </select>
                    </div>

                    <button
                        className="bg-pink-600 text-white px-5 py-2 rounded"
                        onClick={handleEditAmount}
                        disabled={loading}
                    >
                        Update Payment Amount
                    </button>
                </div>
            ) : (
                <div className="text-center text-gray-600">No payment found.</div>
            )}

            {/* Confirmation Modal */}
            {showConfirmationModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
                        <h3 className="text-xl font-bold mb-4 text-center">Confirm Update</h3>
                        <p className="text-center mb-4">You are about to edit this payment, which will affect the student's balance. Please make sure to double-check before proceeding.</p>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="bg-gray-500 text-white px-5 py-2 rounded"
                                onClick={() => setShowConfirmationModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-blue-600 text-white px-5 py-2 rounded"
                                onClick={confirmUpdate}
                            >
                                Okay
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Final Modal */}
            {showFinalModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
                        <h3 className="text-xl font-bold mb-4 text-center">Payment Updated Successfully</h3>
                        <div className="text-center">
                            <button
                                className="bg-blue-600 text-white px-5 py-2 rounded"
                                onClick={() => navigate('/payments')}
                            >
                                Back to Payments
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <ToastContainer />
        </div>
    );
};

export default EditPayment;
