import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { BASE_URL } from '../context/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Settings = () => {
  const { isAuthenticated, username } = useContext(AuthContext); // Access isAuthenticated and username from AuthContext
  const [activeTab, setActiveTab] = useState('changePassword');
  const [users, setUsers] = useState([]);
  const [newUsername, setNewUsername] = useState('');
  const [password, setPassword] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [medicalFee, setMedicalFee] = useState('');
  const [rateFee, setRateFee] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword2, setConfirmPassword2] = useState('');

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (username === 'sysadmin') {
      fetchUsers();
    }
    fetchCustomSettings();
  }, [username]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/users-auth/users`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchCustomSettings = async () => {
    try {
      const medicalFeeResponse = await axios.get(`${BASE_URL}/utilities/fee/medical`);
      const rateFeeResponse = await axios.get(`${BASE_URL}/utilities/fee/rate`);
      console.log(rateFeeResponse)
      setMedicalFee(medicalFeeResponse.data.medicalFee);
      setRateFee(rateFeeResponse.data.rateFee);
    } catch (error) {
      console.error('Error fetching custom settings:', error);
    }
  };

  const handleUpdateMedicalFee = async () => {
    try {
      await axios.put(`${BASE_URL}/utilities/fee/medical/update`, { medicalFee });
      toast.success('Medical fee updated successfully');
    } catch (error) {
      console.error('Error updating medical fee:', error);
      toast.error('Failed to update medical fee');
    }
  };

  const handleUpdateRateFee = async () => {
    try {
      await axios.put(`${BASE_URL}/utilities/fee/rate/update`, { newRateFee : rateFee });
      toast.success('Rate fee updated successfully');
    } catch (error) {
      console.error('Error updating rate fee:', error);
      toast.error('Failed to update rate fee');
    }
  };

  const handleAddUser = async () => {
    try {
      await axios.post(`${BASE_URL}/users-auth/add-user`, { username: newUsername, password });
      toast.success('User added successfully');
      fetchUsers();
      setNewUsername('');
      setPassword('');
    } catch (error) {
      console.error('Error adding user:', error);
      toast.error('Failed to add user');
    }
  };

  const handleEditUser = async () => {
    try {
      await axios.put(`${BASE_URL}/users-auth/users/${selectedUser.id}`, { username: selectedUser.username, password: selectedUser.password });
      toast.success('User updated successfully');
      fetchUsers();
      setSelectedUser(null);
    } catch (error) {
      console.error('Error editing user:', error);
      toast.error('Failed to edit user');
    }
  };

  const handleDeleteUser = async (id, username) => {
    if (username === 'sysadmin' || username === 'financeadmin') {
      toast.error(`Cannot delete ${username}.`);
      return;
    }

    const confirmDelete = window.confirm(`Are you sure you want to delete ${username}?`);
    if (confirmDelete) {
      try {
        await axios.delete(`${BASE_URL}/users-auth/users/${id}`);
        toast.success('User deleted successfully');
        fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
        toast.error('Failed to delete user');
      }
    }
  };


  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    try {
      await axios.post(`${BASE_URL}/users-auth/change-password`, { username: 'sysadmin', oldPassword, newPassword });
      alert('Password updated successfully');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  const renderChangePassword = () => (
    <div className="p-4 bg-white rounded shadow-md mt-5 mx-auto" style={{ maxWidth: '400px' }}>
      <h3 className="text-xl text-center mt-5 mb-8 font-semibold border-b pb-2 ">Change Sysadmin Password</h3>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2" htmlFor="oldPassword">Old Password</label>
        <input
          type="password"
          id="oldPassword"
          className="w-full px-3 py-2 border rounded-lg"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2" htmlFor="newPassword">New Password</label>
        <input
          type="password"
          id="newPassword"
          className="w-full px-3 py-2 border rounded-lg"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2" htmlFor="confirmPassword">Confirm Password</label>
        <input
          type="password"
          id="confirmPassword"
          className="w-full px-3 py-2 border rounded-lg"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
      <button
        className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
        onClick={handleChangePassword}
      >
        Change Password
      </button>
    </div>
  );

  const renderAddUser = () => {
    
    return (
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Add User</h3>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="newUsername">Username</label>
          <input
            type="text"
            id="newUsername"
            className="w-full px-3 py-2 border rounded-lg"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
            required
            autoComplete="off"
          />
        </div>
        <div className="mb-4 relative">
          <label className="block text-gray-700 mb-2" htmlFor="password">Password</label>
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            className="w-full px-3 py-2 border rounded-lg"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="off"
          />
          <button
            type="button"
            className="absolute right-2 top-2 text-blue-500"
            onClick={toggleShowPassword}
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>
        <div className="mb-4 relative">
          <label className="block text-gray-700 mb-2" htmlFor="confirmPassword">Confirm Password</label>
          <input
            type={showPassword ? "text" : "password"}
            id="confirmPassword"
            className="w-full px-3 py-2 border rounded-lg"
            value={confirmPassword2}
            onChange={(e) => setConfirmPassword2(e.target.value)}
            required
            autoComplete="off"
          />
          <button
            type="button"
            className="absolute right-2 top-2 text-blue-500"
            onClick={toggleShowPassword}
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>
        <button
          className="w-full bg-[#BB005A] text-white py-2 rounded-lg hover:bg-pink-200 transition duration-300"
          onClick={handleAddUser}
        >
          Add User
        </button>
      </div>
    );
  };
  

  const renderUsers = () => (
    <div>
      <h3 className="text-xl font-semibold mb-4">Users</h3>
      <table className="min-w-full bg-white border border-gray-200 rounded-lg">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 text-start border-b">Username</th>
            <th className="py-2 px-4 text-start border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td className="py-2 px-4 text-start border-b">{user.username}</td>
              <td className="py-2 px-4 text-start border-b">
                <button
                  onClick={() => setSelectedUser(user)}
                  className="p-2 bg-yellow-500 text-white rounded mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteUser(user.id, user.username)}
                  className="p-2 bg-red-500 text-white rounded"
                >
                  Delete
                </button>

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderEditUser = () => (
    selectedUser && (
      <div className="mb-8 mt-8">
        <h3 className="text-xl font-semibold mb-4">Edit User</h3>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="editUsername">Username</label>
          <input
            type="text"
            id="editUsername"
            className="w-full px-3 py-2 border rounded-lg"
            value={selectedUser.username}
            onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="editPassword">Password</label>
          <input
            type="password"
            id="editPassword"
            className="w-full px-3 py-2 border rounded-lg"
            value={selectedUser.password || ''}
            onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })}
          />
        </div>
        <button
          className="w-full bg-yellow-500 text-white py-2 rounded-lg hover:bg-yellow-600 transition duration-300"
          onClick={handleEditUser}
        >
          Update User
        </button>
      </div>
    )
  );

  const renderCustomSettings = () => (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-4">Custom Settings</h3>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2" htmlFor="medicalFee">Medical Fee</label>
        <input
          type="number"
          id="medicalFee"
          className="w-full px-3 py-2 border rounded-lg"
          value={medicalFee}
          onChange={(e) => setMedicalFee(e.target.value)}
          disabled={!(username === 'sysadmin' || username === 'financeadmin')}
        />
        {username === 'sysadmin' || username === 'financeadmin' ? (
          <button
            className="w-full mt-2 bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
            onClick={handleUpdateMedicalFee}
          >
            Update Medical Fee
          </button>
        ) : null}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 mb-2" htmlFor="rateFee">USD to ZIG Rate</label>
        <input
          type="number"
          id="rateFee"
          className="w-full px-3 py-2 border rounded-lg"
          value={rateFee}
          onChange={(e) => setRateFee(e.target.value)}
          disabled={!(username === 'sysadmin' || username === 'financeadmin')}
        />
        {username === 'sysadmin' || username === 'financeadmin' ? (
          <button
            className="w-full mt-2 bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
            onClick={handleUpdateRateFee}
          >
            Update Rate Fee
          </button>
        ) : null}
      </div>
    </div>
  );

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Settings</h2>
      {!isAuthenticated ? (
        <div>Please log in to access settings.</div>
      ) : username !== 'sysadmin' ? (
        <div>Access denied. Only sysadmin can make these changes.</div>
      ) : (
        <>
          <div className="flex border-b mb-4">
            <button
              className={`px-4 py-2 ${activeTab === 'changePassword' ? 'border-b-2 border-[#BB005A] text-[#BB005A]' : 'text-gray-500'} focus:outline-none`}
              onClick={() => setActiveTab('changePassword')}
            >
              Change Password
            </button>
            <button
              className={`px-4 py-2 ${activeTab === 'addUser' ? 'border-b-2 border-[#BB005A] text-[#BB005A]' : 'text-gray-500'} focus:outline-none`}
              onClick={() => setActiveTab('addUser')}
            >
              Add User
            </button>
            <button
              className={`px-4 py-2 ${activeTab === 'users' ? 'border-b-2 border-[#BB005A] text-[#BB005A]' : 'text-gray-500'} focus:outline-none`}
              onClick={() => setActiveTab('users')}
            >
              View Users
            </button>
            <button
              className={`px-4 py-2 ${activeTab === 'customSettings' ? 'border-b-2 border-[#BB005A] text-[#BB005A]' : 'text-gray-500'} focus:outline-none`}
              onClick={() => setActiveTab('customSettings')}
            >
              Custom Settings
            </button>
          </div>
          <div>
            {activeTab === 'changePassword' && renderChangePassword()}
            {activeTab === 'addUser' && renderAddUser()}
            {activeTab === 'users' && renderUsers()}
            {activeTab === 'customSettings' && renderCustomSettings()}
            {renderEditUser()}
          </div>
          <ToastContainer />
        </>
      )}
    </div>
  );
};

export default Settings;
