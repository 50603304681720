import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditSubjectLevelClass = () => {
  const { classId } = useParams();
  const [formData, setFormData] = useState({
    ClassName: '',
    Form: '',
    ClassType: '',
    Subject: '',
    gradelevelclass: '',
    Teacher: '',
    TeacherName: '',
    Term: '',
    Year: '',
  });
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    fetchClassDetails();
    fetchTeachers();
  }, [classId]);

  const fetchClassDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/subjectlevelclass/${classId}`);
      const classData = response.data;
      setFormData({
        ClassName: classData.ClassName || '',
        Form: classData.Form || '',
        ClassType: classData.ClassType || '',
        Subject: classData.Subject || '',
        gradelevelclass: classData.gradelevelclass || '',
        Teacher: classData.Teacher || '',
        TeacherName: classData.TeacherName || '',
        Term: classData.Term || '',
        Year: classData.Year || '',
      });
    } catch (error) {
      console.error('Error fetching class details:', error);
      toast.error('Failed to fetch class details.');
    }
  };

  const fetchTeachers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/createemployee/teachers/teachers`);
      setTeachers(response.data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      toast.error('Failed to fetch teachers.');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTeacherChange = (e) => {
    const selectedTeacher = teachers.find((teacher) => teacher.EmployeeNumber === e.target.value);
    setFormData({
      ...formData,
      Teacher: selectedTeacher ? selectedTeacher.EmployeeNumber : '',
      TeacherName: selectedTeacher ? `${selectedTeacher.name} ${selectedTeacher.surname}` : '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.ClassName ||
      !formData.Form ||
      !formData.ClassType ||
      !formData.Subject ||
      !formData.Teacher ||
      !formData.Term ||
      !formData.Year
    ) {
      toast.error('Please fill in all fields.');
      return;
    }

    try {
      await axios.put(`${BASE_URL}/subjectlevelclass/${classId}`, formData);
      toast.success('Class updated successfully!');
    } catch (error) {
      console.error('Error updating class:', error);
      toast.error('Failed to update class.');
    }
  };

  // Determine the class type options based on the selected Form
  const classTypeOptions =
    formData.Form >= 5
      ? ['Gold', 'Purple', 'Silver','Sciences', 'Arts', 'Commercials']
      : ['Maroon', 'Gray', 'White', 'Red', 'Orange', 'Green', 'Blue'];

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Edit Subject Level Class</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block">Class Name</label>
          <input
            type="text"
            name="ClassName"
            value={formData.ClassName}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div>
          <label className="block">Form</label>
          <select
            name="Form"
            value={formData.Form}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            <option value="">Select Form</option>
            {[1, 2, 3, 4, 5, 6].map((form) => (
              <option key={form} value={form}>
                {form}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Class Type</label>
          <select
            name="ClassType"
            value={formData.ClassType}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            <option value="">Select Class Type</option>
            {classTypeOptions.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Subject</label>
          <input
            type="text"
            name="Subject"
            value={formData.Subject}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          />
        </div>
        <div>
          <label className="block">Teacher</label>
          <select
            name="Teacher"
            value={formData.Teacher}
            onChange={handleTeacherChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            <option value="">Select Teacher</option>
            {Array.isArray(teachers) && teachers.map((teacher) => (
              <option key={teacher.EmployeeNumber} value={teacher.EmployeeNumber}>
                {`${teacher.name} ${teacher.surname}`}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Term</label>
          <select
            name="Term"
            value={formData.Term}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            <option value="">Select Term</option>
            {[1, 2, 3].map((term) => (
              <option key={term} value={term}>
                {term}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Year</label>
          <select
            name="Year"
            value={formData.Year}
            onChange={handleChange}
            className="border rounded px-4 py-2 w-full"
            required
          >
            <option value="">Select Year</option>
            {Array.from({ length: 78 }, (_, i) => 2023 + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Update Class
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditSubjectLevelClass;
