import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import 'tailwindcss/tailwind.css';
import 'react-toastify/dist/ReactToastify.css';

const LessonPlans = () => {
    const [lessonPlans, setLessonPlans] = useState({});
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [employeeNumber, setEmployeeNumber] = useState('');
    const [date, setDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit] = useState(100); // Limit per page
    const [noResults, setNoResults] = useState(false); // To track if no items are returned
    const [searchTriggered, setSearchTriggered] = useState(false); // To track if the search button is clicked

    // Fetch all lesson plans (paginated) on page load or when page changes
    const fetchAllLessonPlans = async (page = 1) => {
        try {
            const response = await axios.get(`${BASE_URL}/lessons/all-lessons`, {
                params: { page, limit },
            });
            if (response.data && response.data.lessonPlans && Object.keys(response.data.lessonPlans).length > 0) {
                setLessonPlans(response.data.lessonPlans);
                setTotalPages(response.data.pagination.totalPages);
                setNoResults(false); // Reset no results
            } else {
                setLessonPlans({});
                setNoResults(true); // Set no results if nothing is returned
            }
        } catch (error) {
            console.error('Error fetching lesson plans:', error);
        }
    };

    // Fetch filtered lesson plans based on search query
    const fetchFilteredLessonPlans = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/lessons/search`, {
                params: {
                    employeeNumber: employeeNumber || undefined, // Send undefined if empty
                    date: date || undefined, // Send undefined if empty
                    page: currentPage,
                    limit,
                },
            });
            if (response.data && response.data.lessonPlans && Object.keys(response.data.lessonPlans).length > 0) {
                setLessonPlans(response.data.lessonPlans);
                setTotalPages(response.data.pagination.totalPages);
                setNoResults(false); // Reset no results
            } else {
                setLessonPlans({});
                setNoResults(true); // Set no results if nothing is returned
            }
        } catch (error) {
            console.error('Error fetching filtered lesson plans:', error);
        }
    };

    // Only fetch when the search button is clicked or pagination changes
    useEffect(() => {
        if (searchTriggered) {
            fetchFilteredLessonPlans(); // Fetch filtered lesson plans
        } else {
            fetchAllLessonPlans(currentPage); // Fetch all lesson plans if search is not triggered
        }
    }, [currentPage, searchTriggered]);

    // Open modal to view lessons for a specific employee and date
    const openModal = (employeeKey, dateKey) => {
        setSelectedEmployee(employeeKey);
        setSelectedDate(dateKey);
        setIsModalOpen(true);
    };

    // Close modal
    const closeModal = () => {
        setSelectedEmployee(null);
        setSelectedDate(null);
        setIsModalOpen(false);
    };

    // Handle search by employee number and/or date
    const handleSearch = () => {
        setCurrentPage(1); // Reset to the first page for new search
        setSearchTriggered(true); // Trigger search mode
    };

    // Reset the search filters and show all lesson plans
    const handleReset = () => {
        setEmployeeNumber('');
        setDate('');
        setCurrentPage(1); // Reset to the first page
        setNoResults(false); // Reset the no results flag
        setSearchTriggered(false); // Exit search mode
        fetchAllLessonPlans(); // Fetch all lesson plans
    };

    // Handle pagination page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Lesson Plans</h2>

            {/* Search Section */}
            <div className="mb-4 flex space-x-4">
                <input
                    type="text"
                    placeholder="Search by Employee Number"
                    value={employeeNumber}
                    onChange={(e) => setEmployeeNumber(e.target.value)}
                    className="p-2 border rounded"
                />
                <input
                    type="date"
                    placeholder="Search by Date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="p-2 border rounded"
                />
                <button
                    onClick={handleSearch}
                    className="p-2 bg-blue-500 text-white rounded"
                >
                    Search
                </button>
                <button
                    onClick={handleReset}
                    className="p-2 bg-gray-500 text-white rounded"
                >
                    Reset
                </button>
            </div>

            {/* Display message if no results are found */}
            {noResults && (
                <p className="text-center text-gray-500 mt-4">No lesson plans found for the selected criteria.</p>
            )}

            {/* Lesson Plans Table */}
            {Object.keys(lessonPlans).length > 0 && !noResults && (
                <table className="min-w-full text-start bg-white border">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="py-3 text-start px-4 border">Employee Number</th>
                            <th className="py-3 text-start px-4 border">Name</th>
                            <th className="py-3 text-start px-4 border">Date</th>
                            <th className="py-3 text-start px-4 border">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(lessonPlans).map((employeeKey) => {
                            const [employeeNumber, employeeName] = employeeKey.split(' - ');
                            return Object.keys(lessonPlans[employeeKey]).map((dateKey, index) => (
                                <tr key={`${employeeKey}-${dateKey}-${index}`}>
                                    <td className="py-2 px-4 border">{employeeNumber}</td>
                                    <td className="py-2 px-4 border">{employeeName}</td>
                                    <td className="py-2 px-4 border">{new Date(dateKey).toISOString().split('T')[0]}</td>
                                    <td className="py-2 px-4 border">
                                        <button
                                            className="p-2 bg-blue-500 text-white rounded"
                                            onClick={() => openModal(employeeKey, dateKey)}
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ));
                        })}
                    </tbody>
                </table>
            )}

            {/* Pagination Controls */}
            {totalPages > 1 && !noResults && (
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={`px-4 py-2 mx-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}

            {/* Modal */}
            {isModalOpen && selectedEmployee && selectedDate && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-lg relative max-h-[90vh] overflow-y-auto">
                        <button
                            className="absolute top-3 right-3 p-1 bg-red-500 text-white rounded-full"
                            onClick={closeModal}
                        >
                            X
                        </button>
                        <div className="border-b-2 pb-4 mb-4">
                            <h3 className="text-2xl font-bold mb-2">
                                {selectedEmployee.split(' - ')[1]}
                            </h3>
                            <p className="text-gray-600">
                                Employee Number: {selectedEmployee.split(' - ')[0]}
                            </p>
                            <p className="text-gray-500">
                                Date: {new Date(selectedDate).toISOString().split('T')[0]}
                            </p>
                        </div>
                        <div className="space-y-4">
                            {lessonPlans[selectedEmployee][selectedDate].map((lesson, index) => (
                                <div
                                    key={index}
                                    className="p-4 bg-gray-100 rounded-lg shadow-md"
                                >
                                    <h4 className="text-xl font-semibold mb-2">
                                        Lesson Name: {lesson.lessonName}
                                    </h4>
                                    <p className="text-gray-700"><strong>Activity:</strong> {lesson.activity}</p>
                                    <p className="text-gray-700"><strong>Start Time:</strong> {lesson.startTime}</p>
                                    <p className="text-gray-700"><strong>End Time:</strong> {lesson.endTime}</p>
                                </div>
                            ))}
                        </div>
                        <button
                            className="mt-6 p-2 bg-gray-500 text-white rounded-md shadow hover:bg-gray-600 transition duration-200"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}


        </div>
    );
};

export default LessonPlans;
