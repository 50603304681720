import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import AuthContext from '../context/AuthContext'; // Import the AuthContext

const classTypeOptions = {
  form1to4: ['Maroon', 'Gray', 'White', 'Red', 'Orange', 'Green', 'Blue'],
  form5to6: ['Gold', 'Purple', 'Silver','Sciences', 'Arts', 'Commercials']
};

const UpdateBalance = () => {
  const { username } = useContext(AuthContext); // Get the username from AuthContext
  const [regNumber, setRegNumber] = useState('');
  const [form, setForm] = useState('');
  const [term, setTerm] = useState('');
  const [year, setYear] = useState('');
  const [balance, setBalance] = useState('');
  const [balanceType, setBalanceType] = useState('DR');
  const [currency, setCurrency] = useState('USD');
  const [classType, setClassType] = useState('');
  const [classTypeList, setClassTypeList] = useState([]);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (form >= 1 && form <= 4) {
      setClassTypeList(classTypeOptions.form1to4);
    } else if (form >= 5 && form <= 6) {
      setClassTypeList(classTypeOptions.form5to6);
    } else {
      setClassTypeList([]);
    }
  }, [form]);

  const clearForm = () => {
    setRegNumber('');
    setForm('');
    setTerm('');
    setYear('');
    setBalance('');
    setBalanceType('DR');
    setCurrency('USD');
    setClassType('');
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await axios.put(`${BASE_URL}/balances/update`, {
        reg_number: regNumber,
        form: parseInt(form, 10),
        term: parseInt(term, 10),
        year: parseInt(year, 10),
        balance: parseFloat(balance),
        balance_type: balanceType,
        currency,
        class_type: classType
      });
      setModalMessage('Balance updated successfully');
      setIsModalOpen(true);
      clearForm();
      setLoading(false);
    } catch (err) {
      setModalMessage('Failed to update balance');
      setIsModalOpen(true);
      setLoading(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleUpdate();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Restrict access based on username
  if (username !== 'sysadmin' && username !== 'financeadmin') {
    return (
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Access Denied</h2>
        <p>You do not have the privileges to update the balance. Please contact the system administrator.</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Update Balance</h2>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md max-w-sm w-full">
            <h3 className="text-xl font-semibold mb-4">Notification</h3>
            <p>{modalMessage}</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={closeModal}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <form onSubmit={handleFormSubmit} className="space-y-4">
        <div>
          <label className="block">Reg Number:</label>
          <input
            type="text"
            value={regNumber}
            onChange={(e) => setRegNumber(e.target.value)}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Form:</label>
          <select
            value={form}
            onChange={(e) => setForm(e.target.value)}
            className="p-2 border rounded w-full"
            required
          >
            <option value="">Select Form</option>
            {[1, 2, 3, 4, 5, 6].map((form) => (
              <option key={form} value={form}>
                {form}
              </option>
            ))}
          </select>
        </div>
        {classTypeList.length > 0 && (
          <div>
            <label className="block">Class Type:</label>
            <select
              value={classType}
              onChange={(e) => setClassType(e.target.value)}
              className="p-2 border rounded w-full"
              required
            >
              <option value="">Select Class Type</option>
              {classTypeList.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        )}
        <div>
          <label className="block">Term:</label>
          <select
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            className="p-2 border rounded w-full"
            required
          >
            <option value="">Select Term</option>
            {[1, 2, 3].map((term) => (
              <option key={term} value={term}>
                {term}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block">Year:</label>
          <input
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Balance:</label>
          <input
            type="number"
            step="0.01"
            value={balance}
            onChange={(e) => setBalance(e.target.value)}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <div>
          <label className="block">Balance Type:</label>
          <select
            value={balanceType}
            onChange={(e) => setBalanceType(e.target.value)}
            className="p-2 border rounded w-full"
            required
          >
            <option value="DR">Debit</option>
            <option value="CR">Credit</option>
          </select>
        </div>
        <div>
          <label className="block">Currency:</label>
          <input
            type="text"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className="p-2 border rounded w-full"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-pink-700 text-white px-4 py-2 rounded hover:bg-pink-800"
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update Balance'}
        </button>
      </form>
    </div>
  );
};

export default UpdateBalance;
