import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';

const Loader = () => (
  <div className="flex justify-center items-center">
    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
  </div>
);

const NewStudents = () => {
  const [newStudents, setNewStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [regNumber, setRegNumber] = useState('');
  const [form, setForm] = useState('');
  const [year, setYear] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Array of forms (from 1 to 6)
  const forms = [1, 2, 3, 4, 5, 6];

  // Array of years (e.g., from 2020 to 2030)
  const years = Array.from({ length: 11 }, (_, i) => 2020 + i);

  useEffect(() => {
    fetchNewStudents();
  }, [limit, offset]);

  const fetchNewStudents = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/createstudent/new-students`, {
        params: { limit, offset }
      });
      setNewStudents(response.data.data || []);
      setFilteredStudents(response.data.data || []); // Populate both newStudents and filteredStudents
      setTotal(response.data.total || 0);
    } catch (error) {
      console.error('Error fetching new students:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      setFilteredStudents(newStudents); // Reset to full list when search is cleared
    } else {
      const filtered = newStudents.filter(student =>
        student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.surname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.reg_number.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredStudents(filtered);
    }
  };

  const handleResetSearch = () => {
    setSearchTerm('');
    setFilteredStudents(newStudents); // Reset search results to the full list
  };

  const handleNextPage = () => {
    if (offset + limit < total) {
      setOffset(offset + limit);
    }
  };

  const handlePreviousPage = () => {
    if (offset - limit >= 0) {
      setOffset(offset - limit);
    }
  };

  const handleAddNewStudent = async () => {
    if (!regNumber || !form || !year) {
      setErrorMessage('Please fill in all fields');
      return;
    }

    try {
      await axios.post(`${BASE_URL}/createstudent/new-student`, { regNumber, form, year });
      setErrorMessage(''); // Clear any error message
      fetchNewStudents(); // Refresh new students list after adding
      setShowModal(false); // Close modal after adding
      setRegNumber('');
      setForm('');
      setYear('');
    } catch (error) {
      console.error('Error adding new student:', error);
      setErrorMessage('Failed to add student. Please ensure the student is registered.');
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">New Students</h2>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Search..."
            className="border p-2 rounded"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="bg-blue-500 text-white p-2 rounded"
            onClick={handleSearch}
          >
            Search
          </button>
          <button
            className="bg-yellow-500 text-white p-2 rounded"
            onClick={handleResetSearch}
          >
            Reset Search
          </button>
          <button
            className="bg-green-500 text-white p-2 rounded"
            onClick={() => setShowModal(true)}
          >
            Add New Student
          </button>
        </div>
      </div>

      {/* Add New Student Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-md">
            <h3 className="text-xl font-semibold mb-4">Add New Student</h3>
            <input
              type="text"
              placeholder="Reg Number"
              value={regNumber}
              onChange={(e) => setRegNumber(e.target.value)}
              className="border p-2 mb-2 w-full"
            />
            <select
              value={form}
              onChange={(e) => setForm(e.target.value)}
              className="border p-2 mb-2 w-full"
            >
              <option value="" disabled>Select Form</option>
              {forms.map((formValue) => (
                <option key={formValue} value={formValue}>{`Form ${formValue}`}</option>
              ))}
            </select>
            <select
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="border p-2 mb-2 w-full"
            >
              <option value="" disabled>Select Year</option>
              {years.map((yearValue) => (
                <option key={yearValue} value={yearValue}>{yearValue}</option>
              ))}
            </select>
            <div className="flex justify-between">
              <button
                className="bg-green-500 text-white p-2 rounded"
                onClick={handleAddNewStudent}
              >
                Add Student
              </button>
              <button
                className="bg-red-500 text-white p-2 rounded"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
            {errorMessage && (
              <div className="bg-red-100 text-red-700 px-4 py-2 rounded mt-4">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Display new students */}
      <div className="overflow-x-auto">
        {loading ? (
          <Loader />
        ) : filteredStudents.length === 0 ? (
          <div className="flex justify-center items-center h-64">
            <p className="text-center text-gray-500 text-lg">No new students found</p>
          </div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-[#BB005A]">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Surname</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reg Number</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Form</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Year</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredStudents.map((student) => (
                <tr key={student.reg_number}>
                  <td className="px-6 py-4 whitespace-nowrap">{student.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{student.surname}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{student.reg_number}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{student.form}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{student.year}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          className="bg-gray-300 p-2 rounded disabled:opacity-50"
          onClick={handlePreviousPage}
          disabled={offset === 0}
        >
          Previous
        </button>
        <span>
          Page {Math.ceil(offset / limit) + 1} of {Math.ceil(total / limit)}
        </span>
        <button
          className="bg-gray-300 p-2 rounded disabled:opacity-50"
          onClick={handleNextPage}
          disabled={offset + limit >= total}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default NewStudents;
