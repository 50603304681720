import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../context/api';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const Results = () => {
  const [classes, setClasses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAccessDeniedModalOpen, setIsAccessDeniedModalOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const { username } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/getgradelevelclass`);
      setClasses(response.data.classes);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const openPublishModal = () => {
    if (username !== 'sysadmin') {
      setIsAccessDeniedModalOpen(true);
      return;
    }
    setIsModalOpen(true);
  };

  const handlePublishResults = async () => {
    try {
      await axios.post(`${BASE_URL}/publish-results`, {
        TermID: selectedTerm,
        Year: selectedYear,
        Published: true,
      });
      alert('Results published successfully.');
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error publishing results:', error);
      alert('Failed to publish results.');
    }
  };

  const handleUnpublishResults = async () => {
    try {
      await axios.post(`${BASE_URL}/publish-results`, {
        TermID: selectedTerm,
        Year: selectedYear,
        Published: false,
      });
      alert('Results unpublished successfully.');
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error unpublishing results:', error);
      alert('Failed to unpublish results.');
    }
  };

  const handleViewResults = (classID, form) => {
    navigate(`/view-classresults/${classID}/${form}`);
  };

  const filteredClasses = classes.filter((classItem) =>
    classItem.ClassName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Enter class name..."
          value={searchTerm}
          onChange={handleSearch}
          className="border rounded px-4 py-2"
        />
        <button
          onClick={openPublishModal}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Publish Results
        </button>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
              Class Name
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
              Form
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
              Type
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
              Current Teacher
            </th>
            <th className="px-6 py-3 border-b-2 border-gray-300 bg-pink-600 text-left text-xs leading-4 text-white uppercase tracking-wider">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredClasses.map((classItem) => (
            <tr key={classItem.ClassID} className="bg-pink-50">
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {classItem.ClassName}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {classItem.Form}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {classItem.ClassType}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                {classItem.TeacherName}
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  onClick={() => handleViewResults(classItem.ClassID, classItem.Form)}
                >
                  View Results
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Publish Results</h2>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Select Year:</label>
              <input
                type="number"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                className="border rounded px-4 py-2 w-full"
                placeholder="Enter Year"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Select Term:</label>
              <select
                value={selectedTerm}
                onChange={(e) => setSelectedTerm(e.target.value)}
                className="border rounded px-4 py-2 w-full"
              >
                <option value="" disabled>Select Term</option>
                <option value="1">Term 1</option>
                <option value="2">Term 2</option>
                <option value="3">Term 3</option>
              </select>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handlePublishResults}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                Publish
              </button>
              <button
                onClick={handleUnpublishResults}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Unpublish
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {isAccessDeniedModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Access Denied</h2>
            <p className="mb-4">Hey, you are not the sysadmin! Please log in as sysadmin to perform this action.</p>
            <button
              onClick={() => setIsAccessDeniedModalOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Results;
