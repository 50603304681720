import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Set the BASE_URL here
const BASE_URL = 'https://oxfordstudycenterapp.site';

const Assessments = () => {
  const [assessments, setAssessments] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch assessments from the API (general or search based on search query)
  const fetchAssessments = async (page = 1, query = '') => {
    setLoading(true);
    try {
      let response;
      if (query) {
        // Fetch using search API
        response = await axios.get(`${BASE_URL}/assessments/search-assessment`, {
          params: {
            page,
            limit: 10,
            AssessmentName: query, // Search by name
          },
        });
      } else {
        // Fetch all assessments
        response = await axios.get(`${BASE_URL}/assessments/get-all-assessments`, {
          params: {
            page,
            limit: 10,
          },
        });
      }

      setAssessments(response.data.assessments || response.data); // Handle both formats
      setTotalPages(response.data.pagination?.totalPages || 1);
      console.log(response.data.assessments);
    } catch (error) {
      console.error('Error fetching assessments:', error);
      toast.error('Failed to load assessments.');
    } finally {
      setLoading(false);
    }
  };

  // Handle search input change
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Submit search form
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchAssessments(1, searchQuery); // Fetch with search term and reset page to 1
  };

  // Reset search to get all assessments
  const handleResetSearch = () => {
    setSearchQuery(''); // Clear search query
    fetchAssessments(1); // Reset to the first page and fetch all assessments
  };

  // Pagination control
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchAssessments(newPage, searchQuery); // Fetch data for new page
  };

  // Function to download the assessment document
  const handleDownloadDocument = (documentFileName) => {
    if (documentFileName) {
      // Use the BASE_URL for constructing the download URL
      const downloadUrl = `${BASE_URL}/assessments/download-document/${documentFileName}`;
      window.open(downloadUrl, '_blank'); // Open document URL in a new tab for download
    } else {
      toast.error('No document available for download.');
    }
  };

  // Load initial data
  useEffect(() => {
    fetchAssessments(currentPage); // Fetch all assessments initially
  }, [currentPage]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Assessments</h1>

      {/* Search Form */}
      <form onSubmit={handleSearchSubmit} className="mb-4 flex">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search by Assessment Name"
          className="border rounded p-2 flex-1"
        />
        <button
          type="submit"
          className="bg-pink-600 text-white p-2 rounded ml-2"
        >
          Search
        </button>
        <button
          type="button"
          onClick={handleResetSearch}
          className="bg-gray-500 text-white p-2 rounded ml-2"
        >
          Reset Search
        </button>
      </form>

      {/* Loading Indicator */}
      {loading && <p>Loading assessments...</p>}

      {/* No Assessments Found */}
      {!loading && assessments.length === 0 && (
        <p>No assessments found.</p>
      )}

      {/* Assessments List */}
      {!loading && assessments.length > 0 && (
        <table className="min-w-full bg-white shadow-md rounded">
          <thead>
            <tr>
              <th className="py-2 text-start px-4 border-b">Assessment Name</th>
              <th className="py-2 text-start px-4 border-b">Date Created</th>
              <th className="py-2 text-start px-4 border-b">Due Date</th>
              <th className="py-2 text-start px-4 border-b">Class</th>
              <th className="py-2 text-start px-4 border-b">Teacher</th>
              <th className="py-2 text-start px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {assessments.map((assessment) => (
              <tr key={assessment.id}>
                <td className="py-2 px-4 border-b">{assessment.AssessmentName}</td>
                <td className="py-2 px-4 border-b">
                  {new Date(assessment.DateCreated).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border-b">
                  {new Date(assessment.DateDue).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border-b">{assessment.ClassName}</td>
                <td className="py-2 px-4 border-b">
                  {`${assessment.EmployeeName} ${assessment.EmployeeSurname}`}
                </td>
               
                <td className="py-2 px-4 border-b flex space-x-2">
                  {/* Download Document */}
                  <button
                    onClick={() => handleDownloadDocument(assessment.Document)} // Use the document filename
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    Download
                  </button>
                  {/* Results Link */}
                  <Link
                    to={`/assessment-results/${assessment.id}`} // Pass the assessment ID in the URL
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Results
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Pagination Controls */}
      {!loading && totalPages > 1 && (
        <div className="flex justify-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-pink-600 text-white rounded mx-1"
          >
            Previous
          </button>
          <span className="px-4 py-2">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-pink-600 text-white rounded mx-1"
          >
            Next
          </button>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Assessments;
